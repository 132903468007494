import './Footer.scss';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/media-manager-logo.png';
import { useGetRandomMovieQuery } from '../../app/services/movieServerApi';
import { useGetRandomEpisodeQuery } from '../../app/services/tvShowServerApi';
import { useGetRandomAutographQuery } from '../../app/services/autographServerApi';
import { useEffect, useState } from 'react';

export const Footer = () => {
  const location = useLocation();

  const [skipGetRandomMovieItem, setSkipGetRandomMovieItem] = useState(true);
  const [skipGetRandomEpisodeItem, setSkipGetRandomEpisodeItem] =
    useState(true);
  const [skipGetRandomAutographItem, setSkipGetRandomAutographItem] =
    useState(true);

  const {
    data: movieItem = undefined,
    // isLoading: isLoadingMovieItem,
    // // isFetching,
    // isError: isErrorMovieItem,
    // error: errorMovieItem,
  } = useGetRandomMovieQuery(
    { mode: 'background' },
    {
      skip: skipGetRandomMovieItem,
    },
  );
  const {
    data: episodeItem = undefined,
    // isLoading: isLoadingEpisodeItem,
    // // isFetching,
    // isError: isErrorEpisodeItem,
    // error: errorEpisodeItem,
  } = useGetRandomEpisodeQuery(
    { mode: 'background' },
    {
      skip: skipGetRandomEpisodeItem,
    },
  );
  const {
    data: autographItem = undefined,
    // isLoading: isLoadingAutographItem,
    // // isFetching,
    // isError: isErrorAutographItem,
    // error: errorAutographItem,
  } = useGetRandomAutographQuery(
    { mode: 'background' },
    {
      skip: skipGetRandomAutographItem,
    },
  );
  let randomItem = undefined;

  if (location.pathname.includes('autographs')) {
    randomItem = autographItem?.footer;
  } else if (location.pathname.includes('tv-shows')) {
    randomItem = episodeItem?.footer;
  } else {
    randomItem = movieItem?.footer;
  }

  useEffect(() => {
    if (location.pathname.includes('autographs')) {
      setSkipGetRandomAutographItem(false);
    } else if (location.pathname.includes('tv-shows')) {
      setSkipGetRandomEpisodeItem(false);
    } else {
      setSkipGetRandomMovieItem(false);
    }
  }, [location.pathname]);

  const background = randomItem?.background
    ? randomItem?.background
    : randomItem?.cover
      ? randomItem?.cover
      : randomItem?.image;

  return (
    <div
      className="media-footer"
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: `${randomItem?.backgroundHorizontalPosition}% ${randomItem?.backgroundVerticalPosition}%`,
      }}
    >
      <div className="media-footer__content detail-container">
        <div className="media-footer__content__logo">
          <div className="media-logo-footer">
            <img src={logo} alt="media-manager-logo" />
            <Link className="media-a" to="/">
              MediaManager
            </Link>
          </div>
        </div>
        {/* <div className="media-footer__content__menus">
          <div className="media-footer__content__menu">
            <Link className="media-a" to="/">
              Home
            </Link>
          </div>
          <div className="media-footer__content__menu">
            <Link className="media-a" to="/">
              Live
            </Link>
          </div>
          <div className="media-footer__content__menu">
            <Link className="media-a" to="/">
              You must watch
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};
