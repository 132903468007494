// import { useEffect } from 'react';

import { useParams } from 'react-router';

// import { useDispatch, useSelector } from 'react-redux'
import { PageHeader, MediaGrid } from '../../components';

// import { category as cate } from '../api/tmdbApi';

// import { mediaGridSelector, fetchMediaGridItems, fetchFilteredMediaGridItems, setTitle, setPage, fetchArtist } from '../slices/mediaGrid'

export const ArtistCatalog = () => {
  // const dispatch = useDispatch()
  const { id } = useParams();
  // const { selectedArtist, loading, hasErrors, isEmpty, page, totalPages, title, currentTitle } = useSelector(mediaGridSelector)

  // useEffect(() => {

  // //     dispatch(setPage(1))
  // //     window.scrollTo(0, 0)
  // //     if (keyword) {
  // //         dispatch(fetchFilteredMediaGridItems(props.category, 1, keyword))
  // //     } else {
  // //         dispatch(fetchMediaGridItems(props.category, 1))
  // //     }

  // //   }, [dispatch, props.category, keyword])
  //     dispatch(setPage(1))
  //     window.scrollTo(0, 0)
  //     dispatch(fetchArtist(id))
  // }, [dispatch])

  return (
    <>
      <PageHeader title={'selectedArtist?.name'} />

      <PageHeader title={'Movies'} />
      <div className="container">
        <div className="media-section mb-3">
          <MediaGrid category={'movies'} selectedArtistId={id} />
        </div>
      </div>

      <PageHeader title={'TV Shows'} />
      <div className="container">
        <div className="media-section mb-3">
          <MediaGrid category={'tv-shows'} selectedArtistId={id} />
        </div>
      </div>

      <PageHeader title={'Autographs'} />
      <div className="container">
        <div className="media-section mb-3">
          <MediaGrid category={'autographs'} selectedArtistId={id} />
        </div>
      </div>
    </>
  );
};
