import './TVShowCard.scss';

import { Link } from 'react-router-dom';

import { Button } from '../';

// import { category } from '../../api/tmdbApi';
// import apiConfig from '../../api/apiConfig';
// import { useDispatch, useSelector } from 'react-redux'

export const TVShowCard = (props: any) => {
  const item = props.item;

  const link = '/' + props.category + '/' + item.ulid;

  // const bg = apiConfig.w500Image(item.poster_path || item.backdrop_path);
  const background = item.cover;
  // const dispatch = useDispatch()

  return (
    <Link className="media-a" to={link}>
      <div
        className="tv-show-card"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Button className="btn">
          <i className="bi bi-play-fill"></i>
        </Button>
      </div>
      <h3 className="media-h3">{item.title || item.name}</h3>
    </Link>
  );
};
