import { mediaManagerServerApi } from './mediaManagerServerApi';

export const extendedApiSlice = mediaManagerServerApi.injectEndpoints({
  endpoints: (builder) => ({
    getTokens: builder.mutation({
      query: (data) => ({
        url: `authentication/token/`,
        method: 'POST',
        body: data,
      }),
    }),
    refreshToken: builder.mutation({
      query: (data) => ({
        url: `authentication/token/refresh/`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetTokensMutation, useRefreshTokenMutation } =
  extendedApiSlice;
