import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useAppDispatch } from '../../../../app/hooks';
import {
  setCurrentMovieStep,
  setSelectedAddType,
} from '../../../../app/slices/addMediaSlice';

export const MovieStep1 = () => {
  const dispatch = useAppDispatch();
  const setSelectedOption = (selectedOption: string) => {
    dispatch(setSelectedAddType(selectedOption));
    dispatch(setCurrentMovieStep(2));
  };

  return (
    <div className="buttons-container">
      <Button onClick={() => setSelectedOption('new')}>
        <i className="bi bi-film"></i> New
      </Button>
      <Button onClick={() => setSelectedOption('getInfoFromFile')}>
        <i className="bi bi-tv"></i> Get Info from File
      </Button>
    </div>
  );
};
