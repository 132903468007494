import { useRef, useEffect, MutableRefObject } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './Header.scss';
import logo from '../../assets/media-manager-logo.png';
import { RootState } from '../../app/store';
import { logout } from '../../app/slices/authenticationSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Swal from 'sweetalert2';

const navLinks = [
  {
    display: 'Home',
    path: '/',
  },
  {
    display: 'Movies',
    path: '/movies',
  },
  {
    display: 'TV Shows',
    path: '/tv-shows',
  },
  {
    display: 'Autographs',
    path: '/autographs',
  },
];

export const Header = () => {
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const shrinkHeader = () => {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        headerRef.current.classList.add('shrink');
      } else {
        headerRef.current.classList.remove('shrink');
      }
    };
    window.addEventListener('scroll', shrinkHeader);
    return () => {
      window.removeEventListener('scroll', shrinkHeader);
    };
  }, []);

  const userAccessToken = useAppSelector(
    (state: RootState) => state.authentication.userAccessToken,
  );
  const logoutUser = () => {
    dispatch(logout());
    Swal.fire({
      title: 'Logged out succesfully',
      text: 'Redirecting to login page',
      icon: 'success',
      timer: 1000,
      showConfirmButton: false,
    });
  };

  return (
    <div ref={headerRef} className="media-header">
      <div className="media-header__wrap container">
        <div className="media-logo-header">
          <img src={logo} alt="media-manager-logo" />
          <Link className="media-a" to="/">
            MediaManager
          </Link>
        </div>
        {userAccessToken && (
          <ul className="media-header__nav">
            {navLinks.map((navLink, i) => (
              <NavLink
                to={navLink.path}
                className={({ isActive }) =>
                  isActive ? 'media-a navlink active' : 'media-a navlink'
                }
                key={i}
              >
                {navLink.display}
              </NavLink>
            ))}
            <div
              className="media-a navlink"
              role="button"
              tabIndex={0}
              onKeyDown={() => logoutUser()}
              onClick={() => logoutUser()}
            >
              Logout
            </div>
          </ul>
        )}
      </div>
    </div>
  );
};
