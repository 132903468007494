import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useEffect } from 'react';
import { setSelectedMediaType } from '../../../../app/slices/addMediaSlice';
import { resetAutographOrderState } from '../../../../app/slices/addAutographOrderSlice';
import {
  setCurrentAutographStep,
  setSelectedOrderId,
} from '../../../../app/slices/addAutographSlice';

export const Step4 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const newAutographOrderId = useAppSelector(
    (state: RootState) => state.addAutographOrder.newAutographOrderId,
  );
  // const newAutographOrderUlid = useAppSelector(
  //   (state: RootState) => state.addAutographOrder.newAutographOrderUlid,
  // );
  // const generalTvShowData = useAppSelector(
  //   (state: RootState) => state.addTvShow.generalTvShowData,
  // );

  const addAutographSetup = () => {
    dispatch(setSelectedOrderId(newAutographOrderId));
    dispatch(setCurrentAutographStep(2));
    dispatch(setSelectedMediaType('autograph'));
  };

  const finalSetup = () => {
    navigate(`/`);
    dispatch(resetAutographOrderState());
  };
  return (
    <div className="add-media-form-container">
      <div className="buttons-container">
        <Button onClick={() => addAutographSetup()}>Add Autograph</Button>
        <Button onClick={() => finalSetup()}>Go To The Home Page</Button>
      </div>
    </div>
  );
};
