import '../AddMedia.scss';
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  //   MovieStep2,
  //   MovieStep3,
  //   MovieStep4,
  //   MovieStep5,
  //   MovieStep6,
  //   MovieStep7,
  //   MovieStep8,
  //   MovieStep9,
  //   MovieStep10,
} from './Steps';
import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';

export const AddTVShow = () => {
  const currentStep = useAppSelector(
    (state: RootState) => state.addTvShow.currentStep,
  );
  return (
    <div className="add-movie-container">
      {currentStep === 1 && <Step1 />}
      {currentStep === 2 && <Step2 />}
      {currentStep === 3 && <Step3 />}
      {currentStep === 4 && <Step4 />}
      {currentStep === 5 && <Step5 />}
      {currentStep === 6 && <Step6 />}
      {currentStep === 7 && <Step7 />}
    </div>
  );
};
