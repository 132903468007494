import { Button, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useGetTvShowOptionsQuery } from '../../../../app/services/tvShowServerApi';
import { useState } from 'react';
import errorIcon from '../../../../assets/error.svg';
import { TVShowSearchResults } from './TVShowSearchResults';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
// import { setSelectedMovieResult } from '../../../../app/slices/addMediaSlice';
// import {
//   setSelectedTvShowResult,
// } from '../../../../app/slices/addTvShowSeasonSlice';
import { RootState } from '../../../../app/store';
import {
  setCurrentAutographStep,
  setSelectedMovieResult,
  setSelectedTvShowResult,
} from '../../../../app/slices/addAutographSlice';
import { IStep6FormInput } from '../../../../interfaces/forms/AutographForm';
import { useGetMovieOptionsQuery } from '../../../../app/services/movieServerApi';
// import { setSelectedMovieResult } from '../../../../app/slices/addMediaSlice';
import { MovieSearchResults } from './MovieSearchResults';

export const Step6 = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep6FormInput>({
    defaultValues: {
      title: '',
    },
  });

  const selectedMediaType = useAppSelector(
    (state: RootState) => state.addAutograph.selectedMediaType,
  );

  const [currentSearchParams, setCurrentSearchParams] = useState({});
  const [skipGetMovieOptionsQuery, setSkipGetMovieOptionsQuery] =
    useState(true);
  const [skipGetTvShowOptionsQuery, setSkipGetTvShowOptionsQuery] =
    useState(true);

  const [selectedResult, setSelectedResult] = useState<any | null>(null);
  const [showResultsContainer, setShowResultsContainer] = useState(false);

  const {
    data: movieData = {},
    isLoading: isLoadingMovie,
    isFetching: isFetchingMovie,
    isError: isErrorMovie,
    error: errorMovie,
  } = useGetMovieOptionsQuery(currentSearchParams, {
    skip: skipGetMovieOptionsQuery,
  });

  const {
    data: tvShowData = {},
    isLoading: isLoadingTvShow,
    isFetching: isFetchingTvShow,
    isError: isErrorTvShow,
    error: errorTvShow,
  } = useGetTvShowOptionsQuery(currentSearchParams, {
    skip: skipGetTvShowOptionsQuery,
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IStep6FormInput> = (data) => {
    setShowResultsContainer(true);

    if (selectedMediaType === 'movie') {
      setCurrentSearchParams({ movieTitle: data.title });
      setSkipGetMovieOptionsQuery(false);
    } else {
      setCurrentSearchParams({ tvShowTitle: data.title });
      setSkipGetTvShowOptionsQuery(false);
    }
    // console.log('DATA MOVIE STEP 2:', data);
  };

  const setSearchData = () => {
    // console.log('selectedResult:', selectedResult);
    if (selectedMediaType === 'movie') {
      dispatch(
        setSelectedMovieResult({
          id: selectedResult.id,
          imdbId: selectedResult.imdbId,
          title: selectedResult.title,
        }),
      );
    } else {
      dispatch(
        setSelectedTvShowResult({
          id: selectedResult.id,
          imdbId: selectedResult.imdbId,
          title: selectedResult.title,
        }),
      );
    }
    dispatch(setCurrentAutographStep(7));
  };

  const clearSelectedOptions = () => {
    setSelectedResult(null);
  };

  const enableButton = selectedResult;

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 6</h2>
      </div>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for={'title'}>
              {selectedMediaType === 'movie'
                ? 'Movie Title *'
                : 'TV Show Title *'}
            </Label>
            <Controller
              name={'title'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id={'title'}
                  placeholder={`Enter the ${selectedMediaType === 'movie' ? 'movie' : 'tv show'} title"`}
                  type="text"
                  invalid={!!errors.title}
                  {...field}
                />
              )}
            />
            <FormFeedback>{`The ${selectedMediaType === 'movie' ? 'movie' : 'tv show'} title is required"`}</FormFeedback>
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" onClick={() => clearSelectedOptions()}>
              Search
            </Button>
          </div>
        </Form>
      </div>
      {showResultsContainer &&
        (selectedMediaType === 'movie' ? (
          <div className="media-section media-mt-1">
            {isLoadingMovie || isFetchingMovie ? (
              <div className="list-no-item-add">
                <LoadingSpinner />
              </div>
            ) : isErrorMovie ? (
              <div className="list-no-item-add">
                <img src={errorIcon} alt="error-icon" />
                <p>{errorMovie?.error}</p>
              </div>
            ) : Object.keys(movieData).length !== 0 ? (
              <MovieSearchResults
                data={movieData?.movieSearchResults}
                selectedResult={selectedResult}
                setSelectedResult={setSelectedResult}
              />
            ) : null}
          </div>
        ) : (
          <div className="media-section media-mt-1">
            {isLoadingTvShow || isFetchingTvShow ? (
              <div className="list-no-item-add">
                <LoadingSpinner />
              </div>
            ) : isErrorTvShow ? (
              <div className="list-no-item-add">
                <img src={errorIcon} alt="error-icon" />
                <p>{errorTvShow?.error}</p>
              </div>
            ) : Object.keys(tvShowData).length !== 0 ? (
              <TVShowSearchResults
                data={tvShowData?.tvShowSearchResults}
                selectedResult={selectedResult}
                setSelectedResult={setSelectedResult}
              />
            ) : null}
          </div>
        ))}
      {enableButton && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setSearchData()}>Submit</Button>
        </div>
      )}
    </>
  );
};
