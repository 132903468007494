import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IStep6FormInput } from '../../../../interfaces/forms/TVShowForm';
import { usePatchTvShowMutation } from '../../../../app/services/tvShowServerApi';
import { setCurrentStep } from '../../../../app/slices/addTvShowSlice';

export const Step6 = () => {
  // const dispatch = useAppDispatch();

  const finalTvShowData = useAppSelector(
    (state: RootState) => state.addTvShow.finalTvShowData,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep6FormInput>({
    defaultValues: finalTvShowData,
  });

  const [patchTvShow, { isLoading, isError, isSuccess, error }] =
    usePatchTvShowMutation();

  const accessLevels = useAppSelector(
    (state: RootState) => state.addTvShow.accessLevels,
  );

  const itunesId = useAppSelector(
    (state: RootState) => state.addTvShow.selectedItunesResult,
  );

  const dispatch = useAppDispatch();

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      Swal.fire({
        title: 'TV Show created succesfully',
        text: 'Redirecting to tv show page',
        icon: 'success',
        timer: 1000,
        showConfirmButton: false,
      });
    // Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  const onSubmit: SubmitHandler<IStep6FormInput> = (formdata) => {
    formdata.step = 'final_data';
    formdata.itunesId = itunesId;
    patchTvShow(formdata).then(function (result: any) {
      if (result && result.data) {
        dispatch(setCurrentStep(7));
      }
    });
  };

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="accessLevel">Access Level *</Label>
              <Controller
                name="accessLevel"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={accessLevels}
                    placeholder="Select the tv show's access level"
                    error={errors.accessLevel}
                  />
                )}
              />
              {errors.accessLevel && (
                <div className="invalid-message">
                  {"The tv show's access level is required"}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Finish</Button>
        </div>
      </Form>
    </div>
  );
};
