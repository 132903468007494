import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';
// import { useCallback, useEffect, useRef, useState } from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  // useGetMovieQuery,
  useSaveMovieUserMutation,
} from '../../app/services/movieServerApi';
import { useParams } from 'react-router-dom';
import { useSaveEpisodeUserMutation } from '../../app/services/tvShowServerApi';
import { Button, OutlineButton } from '../Button';

export const MediaModal = (props: any) => {
  const item = props.item;
  const subtitles =
    props.category === 'movie'
      ? item?.movieLanguages?.map((movieLanguage: any) => ({
          kind: 'subtitles',
          src: movieLanguage?.subtitleFileVtt,
          srcLang: movieLanguage?.language.label,
        }))
      : item?.episodeLanguages?.map((episodeLanguage: any) => ({
          kind: 'subtitles',
          src: episodeLanguage?.subtitleFileVtt,
          srcLang: episodeLanguage?.language.label,
        }));
  const mediaURL = item.filePath;

  const playerRef = useRef<ReactPlayer>(null);

  const [saveMovieUser] = useSaveMovieUserMutation();
  const [saveEpisodeUser] = useSaveEpisodeUserMutation();

  const { category, id } = useParams();

  const [duration, setDuration] = useState<number | null>(null);

  useEffect(() => {
    if (playerRef.current && !props.isOpen) {
      const currentTime = playerRef.current.getCurrentTime();
      // console.log('currentTime:', currentTime);
      // console.log('duration:', duration);
      if (duration && currentTime < duration) {
        const data = { id: id, seconds_watched: currentTime };
        if (category === 'movies') {
          saveMovieUser(data);
        } else if (category !== 'autographs') {
          saveEpisodeUser(data);
        }
      }
    } else if (props.isOpen) {
      // console.log("settings to false")
      setShowPlayer(
        props?.userItem?.secondsWatched && props.userItem.secondsWatched > 0
          ? false
          : true,
      );
      setIsReady(
        props.userItem.secondsWatched && props.userItem.secondsWatched > 0
          ? false
          : true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const handleProgress = (state: any) => {
    // console.log('onProgress', state);
    const playedSeconds = state.playedSeconds;
    if (duration && playedSeconds < duration) {
      const data = { id: id, seconds_watched: playedSeconds };
      if (category === 'movies') {
        saveMovieUser(data);
      } else if (category === 'tv-shows') {
        saveEpisodeUser(data);
      }
    }
    // We only want to update time slider if we are not currently seeking
  };

  const handleEnded = () => {
    // console.log('onEnded');
    const data = { id: id, watched: true };
    if (category === 'movies') {
      saveMovieUser(data);
    } else if (category === 'tv-shows') {
      saveEpisodeUser(data);
    }
  };

  const handleDuration = (duration: number) => {
    // console.log('onDuration', duration);
    setDuration(duration);
  };

  // const handleOnReady = useCallback(() => {
  //   if (playerRef.current) {
  //     playerRef.current.seekTo(30, 'seconds');
  //   }
  // }, [playerRef.current]);

  const [isReady, setIsReady] = useState(false);

  const [resumePlayback, setResumePlayback] = useState(false);

  const handleResume = (resume: boolean) => {
    if (resume) {
      setResumePlayback(true);
    } else {
      setResumePlayback(false);
    }
    setShowPlayer(true);
    //   if (playerRef.current) {
    //     playerRef.current.seekTo(10, 'seconds')
    // }
  };

  // const handleOnReady = useCallback(() => {
  //   console.log("handle on ready fired")
  //   if (!isReady && playerRef.current && props?.userItem?.secondsWatched) {
  //     console.log("ABOUT TO SET TIME")
  //     // const timeToStart = (7 * 60) + 12.6;
  //     playerRef.current.seekTo(props.userItem.secondsWatched, "seconds");
  //     setIsReady(true);
  //   }
  // }, [isReady]);

  const handleOnReady = () => {
    // console.log("handle on ready fired")
    if (!isReady && playerRef.current && props?.userItem?.secondsWatched) {
      // console.log("ABOUT TO SET TIME")
      // const timeToStart = (7 * 60) + 12.6;
      // console.log("resumePlayback:", resumePlayback)
      if (resumePlayback) {
        playerRef.current.seekTo(props.userItem.secondsWatched, 'seconds');
      }
      setIsReady(true);
    }
  };

  // console.log('props.isOpen:', props.isOpen);

  // console.log(
  //   'props.userItem:',
  //   props.userItem,
  // );

  // const [showPlayer, setShowPlayer] = useState(props?.userItem?.secondsWatched &&
  //   props.userItem.secondsWatched > 0 ? false : true)

  const [showPlayer, setShowPlayer] = useState(false);

  // useEffect(() => {
  //   setShowPlayer(props.userItem.secondsWatched &&
  //   props.userItem.secondsWatched > 0 ? false : true)
  //   // setIsReady(props.userItem.secondsWatched &&
  //   //   props.userItem.secondsWatched > 0 ? false : true)
  // }, [props.userItem.secondsWatched])

  // console.log("showPlayer:", showPlayer)

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered={true}
        className="modal-dialog modal-xl"
      >
        <div style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
          <ModalHeader style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
            <div
              className="media-modal__content__close"
              role="button"
              tabIndex={0}
              onKeyDown={props.toggle}
              onClick={props.toggle}
            >
              <i className="bi bi-x"></i>
            </div>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#0f0f0f' }}>
            {!showPlayer ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Button onClick={() => handleResume(true)}>Resume</Button>
                <OutlineButton onClick={() => handleResume(false)}>
                  Start from the beginning
                </OutlineButton>
              </div>
            ) : (
              <ReactPlayer
                id="videomedia"
                config={{
                  file: {
                    attributes: {
                      crossOrigin: 'true',
                    },
                    tracks: subtitles,
                  },
                }}
                url={mediaURL}
                ref={playerRef}
                width="100%"
                height="100%"
                progressInterval={300000}
                onProgress={handleProgress}
                onReady={handleOnReady}
                onDuration={handleDuration}
                onEnded={handleEnded}
                controls
                // onCallback
              />
            )}
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};
