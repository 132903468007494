// import { useState } from 'react';
import { LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useGetMediaDataQuery } from '../../../../app/services/movieServerApi';
import errorIcon from '../../../../assets/error.svg';
// import noDataIcon from '../../../../assets/nodata.svg';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { MovieForm } from './MovieForm';
import { useEffect } from 'react';

export const MovieStep4 = () => {
  const {
    // data = {},
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetMediaDataQuery({
    selectedItunesResult: useAppSelector(
      (state: RootState) => state.addMedia.selectedItunesResult,
    ),
    selectedImdbResult: useAppSelector(
      (state: RootState) => state.addMedia.selectedImdbResult,
    ),
    selectedItunesCountry: useAppSelector(
      (state: RootState) => state.addMedia.selectedItunesCountry,
    ),
  });

  // const {
  //   control,
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  // } = useForm<IMovieStep4FormInput>({
  //   defaultValues: {},

  //   //   defaultValues: useMemo(() => {
  //   //     return data?.movieData;
  //   // }, [data])
  // });

  // console.log('MOVIE DATA:', data?.movieData);

  // useEffect(() => {
  //   if (data?.movieData) {
  //     console.log('RESETTED');
  //     reset(data.movieData);
  //   }
  // }, [data]);

  // console.log('THE REAL DATA ITUNES AND IMDB:', data);
  // const dispatch = useAppDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  return (
    <>
      {isLoading || isFetching ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : isSuccess ? (
        <MovieForm />
      ) : null}
    </>
  );
};
