import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useAppDispatch } from '../../../../app/hooks';
import { setCurrentAutographStep } from '../../../../app/slices/addAutographSlice';

export const Step12 = () => {
  const dispatch = useAppDispatch();
  const setSelectedOption = (selectedOption: boolean) => {
    if (selectedOption === true) {
      dispatch(setCurrentAutographStep(9));
    } else {
      dispatch(setCurrentAutographStep(13));
    }
  };

  return (
    <>
      <div className="media-section__subtitle">
        <h2 className="media-h2">
          Step 12: Does the Autograph Have Another COA?
        </h2>
      </div>
      <div className="buttons-container">
        <Button onClick={() => setSelectedOption(true)}>
          <i className="bi bi-film"></i> Yes
        </Button>
        <Button onClick={() => setSelectedOption(false)}>
          <i className="bi bi-tv"></i> No
        </Button>
      </div>
    </>
  );
};
