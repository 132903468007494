import { Button, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import errorIcon from '../../../../assets/error.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
// import { TVShowSeasonForm } from './TVShowSeasonForm';
import { useEffect } from 'react';
import { useGetTvSeasonEpisodesQuery } from '../../../../app/services/tvShowServerApi';
import {
  setCurrentTvShowSeasonEpisodeStep,
  setSelectedTvShowSeasonEpisode,
} from '../../../../app/slices/addTvShowSeasonEpisodeSlice';

export const Step2 = () => {
  //   const tvShowSeasonEpisodesData  = useAppSelector((state: RootState) => state.addTvShowSeasonEpisode.tvShowSeasonEpisodesData);
  const {
    data = {},
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTvSeasonEpisodesQuery({
    selectedSeason: useAppSelector(
      (state: RootState) =>
        state.addTvShowSeasonEpisode.selectedTvShowSeasonResult.id,
    ),
  });
  // console.log('data:', data);
  const dispatch = useAppDispatch();

  const onSelect = (imdbId: string) => {
    dispatch(setSelectedTvShowSeasonEpisode(imdbId));
    dispatch(setCurrentTvShowSeasonEpisodeStep(3));
    // console.log('DATA MOVIE STEP 2:', data);
  };

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  return (
    <>
      {isLoading || isFetching ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : isSuccess ? (
        // <TVShowSeasonForm />

        <div className="add-media-form-container">
          <div className="buttons-container-vertical">
            {data?.tvShowSeasonEpisodesData?.map((item: any, i: number) => (
              <Button
                onClick={() => onSelect(item.imdbId)}
                key={i}
                disabled={item.alreadyAdded}
              >
                <i
                  className={item.alreadyAdded ? 'bi bi-check' : 'bi bi-x'}
                ></i>{' '}
                {item.episodeNumber}. {item.episodeTitle}
              </Button>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};
