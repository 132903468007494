import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IStep3FormInput } from '../../../../interfaces/forms/TVShowSeasonEpisodeForm/IStep3Form';
import {
  setCurrentTvShowSeasonEpisodeStep,
  setGeneralTvShowSeasonEpisodeData,
} from '../../../../app/slices/addTvShowSeasonEpisodeSlice';

export const TVShowSeasonEpisodeForm = () => {
  const dispatch = useAppDispatch();
  const generalTvShowSeasonEpisodeData = useAppSelector(
    (state: RootState) =>
      state.addTvShowSeasonEpisode.generalTvShowSeasonEpisodeData,
  );
  const qualities = useAppSelector(
    (state: RootState) => state.addTvShowSeasonEpisode.qualities,
  );
  const formats = useAppSelector(
    (state: RootState) => state.addTvShowSeasonEpisode.formats,
  );
  const sources = useAppSelector(
    (state: RootState) => state.addTvShowSeasonEpisode.sources,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep3FormInput>({
    defaultValues: generalTvShowSeasonEpisodeData,
  });

  const onSubmit: SubmitHandler<IStep3FormInput> = (formdata) => {
    dispatch(setGeneralTvShowSeasonEpisodeData(formdata));
    dispatch(setCurrentTvShowSeasonEpisodeStep(4));
  };

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="title">Title *</Label>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="title"
                    placeholder="Enter the tv show season episode's title"
                    type="text"
                    invalid={!!errors.title}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season episode's title is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="originalTitle">Original Title</Label>
              <Controller
                name="originalTitle"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="originalTitle"
                    placeholder="Enter the tv show season episode's original title"
                    type="text"
                    invalid={!!errors.originalTitle}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="itunesTitle">iTunes Title</Label>
              <Controller
                name="itunesTitle"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="itunesTitle"
                    placeholder="Enter the tv show season episode's iTunes title"
                    type="text"
                    invalid={!!errors.itunesTitle}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="number">Number *</Label>
              <Controller
                name="number"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="number"
                    placeholder="Enter the tv show season episode's number"
                    type="number"
                    invalid={!!errors.number}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season episode's number is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="code">Code *</Label>
              <Controller
                name="code"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="code"
                    placeholder="Enter the tv show season episode's code"
                    type="text"
                    invalid={!!errors.code}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season episode's code is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="shortDescription">Short Description *</Label>
              <Controller
                name="shortDescription"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="shortDescription"
                    placeholder="Enter the tv show season episode's short description"
                    type="textarea"
                    invalid={!!errors.shortDescription}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season episode's short description is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="description">Description *</Label>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="description"
                    placeholder="Enter the tv show season episode's description"
                    type="textarea"
                    invalid={!!errors.description}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season episode's description is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="releaseDate">Release Date *</Label>
              <Controller
                name="releaseDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="releaseDate"
                    placeholder="Enter the tv show season episode's release date"
                    type="text"
                    invalid={!!errors.releaseDate}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season episode's release date is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="quality">Quality *</Label>
              <Controller
                name="quality"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={qualities}
                    placeholder="Select the tv show season episode's quality"
                    error={errors.quality}
                  />
                )}
              />
              {errors.quality && (
                <div className="invalid-message">
                  {"The tv show season episode's quality is required"}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="format">Format *</Label>
              <Controller
                name="format"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={formats}
                    placeholder="Select the tv show season episode's format"
                    error={errors.format}
                  />
                )}
              />
              {errors.format && (
                <div className="invalid-message">
                  {"The tv show season episode's format is required"}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="source">Source *</Label>
              <Controller
                name="source"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={sources}
                    placeholder="Select the tv show season episode's source"
                    error={errors.source}
                  />
                )}
              />
              {errors.source && (
                <div className="invalid-message">
                  {"The tv show season episode's source is required"}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="sourceLink">Source Link</Label>
              <Controller
                name="sourceLink"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="sourceLink"
                    placeholder="Enter the tv show season episode's source link"
                    type="text"
                    invalid={!!errors.sourceLink}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {generalTvShowSeasonEpisodeData.itunesId !== '' && (
            <Col md={4}>
              <FormGroup>
                <Label for="itunesId">iTunes ID *</Label>
                <Controller
                  name="itunesId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="itunesId"
                      placeholder="Enter the tv show season episode's iTunes ID"
                      type="text"
                      invalid={!!errors.itunesId}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {"The tv show season episode's iTunes ID is required"}
                </FormFeedback>
              </FormGroup>
            </Col>
          )}
          <Col md={generalTvShowSeasonEpisodeData.itunesId !== '' ? 4 : 6}>
            <FormGroup>
              <Label for="imdbId">IMDb ID *</Label>
              <Controller
                name="imdbId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="imdbId"
                    placeholder="Enter the tv show season episode's IMDb ID"
                    type="text"
                    invalid={!!errors.imdbId}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season episode's IMDb ID is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={generalTvShowSeasonEpisodeData.itunesId !== '' ? 4 : 6}>
            <FormGroup>
              <Label for="imdbRating">IMDb Rating</Label>
              <Controller
                name="imdbRating"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="imdbRating"
                    placeholder="Enter the tv show season episode's IMDb rating"
                    type="text"
                    invalid={!!errors.imdbRating}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="trailerUrl">Trailer URL</Label>
              <Controller
                name="trailerUrl"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="trailerUrl"
                    placeholder="Enter the tv show season episode's trailer URL"
                    type="text"
                    invalid={!!errors.trailerUrl}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};
