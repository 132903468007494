import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IStep5FormInput } from '../../../../interfaces/forms/TVShowSeasonForm';
import {
  setCurrentTvShowSeasonStep,
  setNewTvShowSeasonId,
  setNewTvShowSeasonUlid,
} from '../../../../app/slices/addTvShowSeasonSlice';
import { ILoginFormError } from '../../../../interfaces/forms';
import Swal from 'sweetalert2';
import { useCreateTvShowSeasonMutation } from '../../../../app/services/tvShowServerApi';

// export const MovieForm = ({ data }: any) => {
export const TVShowSeasonForm = () => {
  const dispatch = useAppDispatch();

  const generalTvShowSeasonData = useAppSelector(
    (state: RootState) => state.addTvShowSeason.generalTvShowSeasonData,
  );

  const selectedDataSource = useAppSelector(
    (state: RootState) => state.addTvShowSeason.selectedDataSource,
  );
  const networks = useAppSelector(
    (state: RootState) => state.addTvShowSeason.networks,
  );
  const selectedTvShowResult = useAppSelector(
    (state: RootState) => state.addTvShowSeason.selectedTvShowResult,
  );

  const {
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm<IStep5FormInput>({
    // defaultValues: data.movieData,
    defaultValues: generalTvShowSeasonData,

    //   defaultValues: useMemo(() => {
    //     return data?.movieData;
    // }, [data])
  });

  const [createTvShowSeason, { isLoading, isError, isSuccess, error }] =
    useCreateTvShowSeasonMutation();

  const onSubmit: SubmitHandler<IStep5FormInput> = (formdata) => {
    // login(data).then(function (result: any) {
    //   if (result && result.data) {
    // dispatch(setGeneralTvShowData(formdata));
    // dispatch(setCurrentStep(4));

    //   }
    // })
    // console.log('DATA TV SHOW STEP 3:', formdata);
    // formdata.tvShowId = selectedTvShowResult.id
    const formData = { ...formdata, tvShowId: selectedTvShowResult.id };

    createTvShowSeason(formData).then(function (result: any) {
      if (result && result.data) {
        dispatch(setNewTvShowSeasonId(result.data.tvShowSeasonId));
        dispatch(setNewTvShowSeasonUlid(result.data.tvShowSeasonUlid));
        dispatch(setCurrentTvShowSeasonStep(6));
      }
    });
  };

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  // console.log('IS LOADING:', isLoading);

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1500,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="title">TV Show Season Title *</Label>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="title"
                    placeholder="Enter the tv show season's title"
                    type="text"
                    invalid={!!errors.title}
                    {...field}
                  />
                )}
              />
              <FormFeedback>{"The tv show's title is required"}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="seasonNumber">TV Show Season Number</Label>
              <Controller
                name="seasonNumber"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="seasonNumber"
                    placeholder="Enter the tv show season's number"
                    type="number"
                    invalid={!!errors.seasonNumber}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season's number is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="shortDescription">Short Description *</Label>
              <Controller
                name="shortDescription"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="shortDescription"
                    placeholder="Enter the tv show season's short description"
                    type="textarea"
                    invalid={!!errors.shortDescription}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season's short description is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="description">Description *</Label>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="description"
                    placeholder="Enter the tv show season's description"
                    type="textarea"
                    invalid={!!errors.description}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show season's description is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="copyright">Copyright</Label>
              <Controller
                name="copyright"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="copyright"
                    placeholder="Enter the tv show season's copyright"
                    type="text"
                    invalid={!!errors.copyright}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={selectedDataSource === 'itunesAndImdb' ? 6 : 12}>
            <FormGroup>
              <Label for="network">Network</Label>
              <Controller
                name="network"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={networks}
                    placeholder="Select the tv show season's network"
                    error={errors.network}
                  />
                )}
              />
              {errors.network && (
                <div className="invalid-message">
                  {"The tv show season's network is required"}
                </div>
              )}
            </FormGroup>
          </Col>

          {selectedDataSource === 'itunesAndImdb' && (
            <Col md={6}>
              <FormGroup>
                <Label for="itunesId">iTunes ID *</Label>
                <Controller
                  name="itunesId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="itunesId"
                      placeholder="Enter the tv show season's iTunes ID"
                      type="text"
                      invalid={!!errors.itunesId}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {"The movie's iTunes ID is required"}
                </FormFeedback>
              </FormGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="trailerUrl">Trailer URL</Label>
              <Controller
                name="trailerUrl"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="trailerUrl"
                    placeholder="Enter the tv show season's trailer URL"
                    type="text"
                    invalid={!!errors.trailerUrl}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};
