export const itunesCountries = [
  {
    value: 'us',
    label: 'United States of America',
  },

  {
    value: 'gb',
    label: 'United Kingdom',
  },
  {
    value: 'al',
    label: 'Albania',
  },
  {
    value: 'dz',
    label: 'Algeria',
  },
  {
    value: 'ao',
    label: 'Angola',
  },
  {
    value: 'ai',
    label: 'Anguilla',
  },
  {
    value: 'ag',
    label: 'Antigua and Barbuda',
  },
  {
    value: 'ar',
    label: 'Argentina',
  },
  {
    value: 'am',
    label: 'Armenia',
  },
  {
    value: 'au',
    label: 'Australia',
  },
  {
    value: 'at',
    label: 'Austria',
  },
  {
    value: 'az',
    label: 'Azerbaijan',
  },
  {
    value: 'bs',
    label: 'Bahamas',
  },
  {
    value: 'bh',
    label: 'Bahrain',
  },
  {
    value: 'bb',
    label: 'Barbados',
  },
  {
    value: 'by',
    label: 'Belarus',
  },
  {
    value: 'be',
    label: 'Belgium',
  },
  {
    value: 'bz',
    label: 'Belize',
  },
  {
    value: 'bj',
    label: 'Benin',
  },
  {
    value: 'bm',
    label: 'Bermuda',
  },
  {
    value: 'bt',
    label: 'Bhutan',
  },
  {
    value: 'bo',
    label: 'Bolivia',
  },
  {
    value: 'bw',
    label: 'Botswana',
  },
  {
    value: 'br',
    label: 'Brazil',
  },
  {
    value: 'vg',
    label: 'British Virgin Islands',
  },
  {
    value: 'bn',
    label: 'Brunei Darussalam',
  },
  {
    value: 'bg',
    label: 'Bulgaria',
  },
  {
    value: 'bf',
    label: 'Burkina-Faso',
  },
  {
    value: 'kh',
    label: 'Cambodia',
  },
  {
    value: 'ca',
    label: 'Canada',
  },
  {
    value: 'cv',
    label: 'Cape Verde',
  },
  {
    value: 'ky',
    label: 'Cayman Islands',
  },
  {
    value: 'td',
    label: 'Chad',
  },
  {
    value: 'cl',
    label: 'Chile',
  },
  {
    value: 'cn',
    label: 'China',
  },
  {
    value: 'co',
    label: 'Colombia',
  },
  {
    value: 'cr',
    label: 'Costa Rica',
  },
  {
    value: 'hr',
    label: 'Croatia',
  },
  {
    value: 'cy',
    label: 'Cyprus',
  },
  {
    value: 'cz',
    label: 'Czech Republic',
  },
  {
    value: 'cg',
    label: 'Democratic Republic of the Congo',
  },
  {
    value: 'dk',
    label: 'Denmark',
  },
  {
    value: 'dm',
    label: 'Dominica',
  },
  {
    value: 'do',
    label: 'Dominican Republic',
  },
  {
    value: 'ec',
    label: 'Ecuador',
  },
  {
    value: 'eg',
    label: 'Egypt',
  },
  {
    value: 'sv',
    label: 'El Salvador',
  },
  {
    value: 'ee',
    label: 'Estonia',
  },
  {
    value: 'fm',
    label: 'Federated States of Micronesia',
  },
  {
    value: 'fj',
    label: 'Fiji',
  },
  {
    value: 'fi',
    label: 'Finland',
  },
  {
    value: 'fr',
    label: 'France',
  },
  {
    value: 'gm',
    label: 'Gambia',
  },
  {
    value: 'de',
    label: 'Germany',
  },
  {
    value: 'gh',
    label: 'Ghana',
  },
  {
    value: 'gr',
    label: 'Greece',
  },
  {
    value: 'gd',
    label: 'Grenada',
  },
  {
    value: 'gt',
    label: 'Guatemala',
  },
  {
    value: 'gw',
    label: 'Guinea Bissau',
  },
  {
    value: 'gy',
    label: 'Guyana',
  },
  {
    value: 'hn',
    label: 'Honduras',
  },
  {
    value: 'hk',
    label: 'Hong Kong',
  },
  {
    value: 'hu',
    label: 'Hungary',
  },
  {
    value: 'is',
    label: 'Iceland',
  },
  {
    value: 'in',
    label: 'India',
  },
  {
    value: 'value',
    label: 'Indonesia',
  },
  {
    value: 'ie',
    label: 'Ireland',
  },
  {
    value: 'il',
    label: 'Israel',
  },
  {
    value: 'it',
    label: 'Italy',
  },
  {
    value: 'jm',
    label: 'Jamaica',
  },
  {
    value: 'jp',
    label: 'Japan',
  },
  {
    value: 'jo',
    label: 'Jordan',
  },
  {
    value: 'kz',
    label: 'Kazakhstan',
  },
  {
    value: 'ke',
    label: 'Kenya',
  },
  {
    value: 'kg',
    label: 'Krygyzstan',
  },
  {
    value: 'kw',
    label: 'Kuwait',
  },
  {
    value: 'la',
    label: 'Laos',
  },
  {
    value: 'lv',
    label: 'Latvia',
  },
  {
    value: 'lb',
    label: 'Lebanon',
  },
  {
    value: 'lr',
    label: 'Liberia',
  },
  {
    value: 'lt',
    label: 'Lithuania',
  },
  {
    value: 'lu',
    label: 'Luxembourg',
  },
  {
    value: 'mo',
    label: 'Macau',
  },
  {
    value: 'mk',
    label: 'Macedonia',
  },
  {
    value: 'mg',
    label: 'Madagascar',
  },
  {
    value: 'mw',
    label: 'Malawi',
  },
  {
    value: 'my',
    label: 'Malaysia',
  },
  {
    value: 'ml',
    label: 'Mali',
  },
  {
    value: 'mt',
    label: 'Malta',
  },
  {
    value: 'mr',
    label: 'Mauritania',
  },
  {
    value: 'mu',
    label: 'Mauritius',
  },
  {
    value: 'mx',
    label: 'Mexico',
  },
  {
    value: 'md',
    label: 'Moldova',
  },
  {
    value: 'mn',
    label: 'Mongolia',
  },
  {
    value: 'ms',
    label: 'Montserrat',
  },
  {
    value: 'mz',
    label: 'Mozambique',
  },
  {
    value: 'na',
    label: 'Namibia',
  },
  {
    value: 'np',
    label: 'Nepal',
  },
  {
    value: 'nl',
    label: 'Netherlands',
  },
  {
    value: 'nz',
    label: 'New Zealand',
  },
  {
    value: 'ni',
    label: 'Nicaragua',
  },
  {
    value: 'ne',
    label: 'Niger',
  },
  {
    value: 'ng',
    label: 'Nigeria',
  },
  {
    value: 'no',
    label: 'Norway',
  },
  {
    value: 'om',
    label: 'Oman',
  },
  {
    value: 'pk',
    label: 'Pakistan',
  },
  {
    value: 'pw',
    label: 'Palau',
  },
  {
    value: 'pa',
    label: 'Panama',
  },
  {
    value: 'pg',
    label: 'Papua New Guinea',
  },
  {
    value: 'py',
    label: 'Paraguay',
  },
  {
    value: 'pe',
    label: 'Peru',
  },
  {
    value: 'ph',
    label: 'Philippines',
  },
  {
    value: 'pl',
    label: 'Poland',
  },
  {
    value: 'pt',
    label: 'Portugal',
  },
  {
    value: 'qa',
    label: 'Qatar',
  },
  {
    value: 'tt',
    label: 'Republic of Trinidad and Tobago',
  },
  {
    value: 'ro',
    label: 'Romania',
  },
  {
    value: 'ru',
    label: 'Russia',
  },
  {
    value: 'kn',
    label: 'Saint Kitts and Nevis',
  },
  {
    value: 'lc',
    label: 'Saint Lucia',
  },
  {
    value: 'vc',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    value: 'st',
    label: 'Sao Tome e Principe',
  },
  {
    value: 'sa',
    label: 'Saudi Arabia',
  },
  {
    value: 'sn',
    label: 'Senegal',
  },
  {
    value: 'sc',
    label: 'Seychelles',
  },
  {
    value: 'sl',
    label: 'Sierra Leone',
  },
  {
    value: 'sg',
    label: 'Singapore',
  },
  {
    value: 'sk',
    label: 'Slovakia',
  },
  {
    value: 'si',
    label: 'Slovenia',
  },
  {
    value: 'sb',
    label: 'Soloman Islands',
  },
  {
    value: 'za',
    label: 'South Africa',
  },
  {
    value: 'kr',
    label: 'South Korea',
  },
  {
    value: 'es',
    label: 'Spain',
  },
  {
    value: 'lk',
    label: 'Sri Lanka',
  },
  {
    value: 'sr',
    label: 'Suriname',
  },
  {
    value: 'sz',
    label: 'Swaziland',
  },
  {
    value: 'se',
    label: 'Sweden',
  },
  {
    value: 'ch',
    label: 'Switzerland',
  },
  {
    value: 'tw',
    label: 'Taiwan',
  },
  {
    value: 'tj',
    label: 'Tajikistan',
  },
  {
    value: 'tz',
    label: 'Tanzania',
  },
  {
    value: 'th',
    label: 'Thailand',
  },
  {
    value: 'tn',
    label: 'Tunisia',
  },
  {
    value: 'tr',
    label: 'Turkey',
  },
  {
    value: 'tm',
    label: 'Turkmenistan',
  },
  {
    value: 'tc',
    label: 'Turks and Caicos Islands',
  },
  {
    value: 'ug',
    label: 'Uganda',
  },
  {
    value: 'ua',
    label: 'Ukraine',
  },
  {
    value: 'ae',
    label: 'United Arab Emirates',
  },
  {
    value: 'gb',
    label: 'United Kingdom',
  },
  {
    value: 'us',
    label: 'United States of America',
  },
  {
    value: 'uy',
    label: 'Uruguay',
  },
  {
    value: 'uz',
    label: 'Uzbekistan',
  },
  {
    value: 've',
    label: 'Venezuela',
  },
  {
    value: 'vn',
    label: 'Vietnam',
  },
  {
    value: 'ye',
    label: 'Yemen',
  },
  {
    value: 'zw',
    label: 'Zimbabwe',
  },
];
