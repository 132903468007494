import './TVShowList.scss';
/* eslint-disable import/no-unresolved */
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';
import { TVShowCard, LoadingSpinner } from '../';
import { useGetTVShowsQuery, useGetSimilarTVShowsQuery } from '../../app/services/tvShowServerApi';
import errorIcon from '../../assets/error.svg';
import noDataIcon from '../../assets/nodata.svg';

export const TVShowList = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);
  const {
    data: tvShowsData = {},
    isLoading: isLoadingTVShows,
    // isSuccess,
    // isFetching,
    isError: isErrorTVShows,
    error: errorTVShows,
  } = useGetTVShowsQuery({ page: 1, mode: 'home' },
    {
      skip: props?.mode === "similar",
    });

  const {
    data: similarTVShowsData = {},
    isLoading: isLoadingSimilarTVShows,
    // isSuccessSimilar,
    // isFetchingSimilar,
    isError: isErrorSimilarTVShows,
    error: errorSimilarTVShows,
  } = useGetSimilarTVShowsQuery({ page: 1, mode: 'similar', id: props.ulid },
    {
      skip: props?.mode !== "similar",
    },);

  const data = props?.mode === "similar" ? similarTVShowsData : tvShowsData;
  const isLoading = props?.mode === "similar" ? isLoadingSimilarTVShows : isLoadingTVShows;
  const isError = props?.mode === "similar" ? isErrorSimilarTVShows : isErrorTVShows;
  const error = props?.mode === "similar" ? errorSimilarTVShows : errorTVShows;

  return (
    <div className="tv-show-list">
      {isLoading ? (
        <div className="list-no-item">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : data.results.length === 0 ? (
        <div className="list-no-item">
          <img src={noDataIcon} alt="no-item-icon" />
          <p>{'No Items'}</p>
        </div>
      ) : (
        <Swiper
          grabCursor={true}
          spaceBetween={10}
          slidesPerView={'auto'}
          mousewheel={false}
          keyboard={true}
        >
          {data.results.map((item: any, i: number) => (
            <SwiperSlide key={i}>
              <TVShowCard item={item} category={props.category} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
