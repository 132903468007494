import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

import { useAppDispatch } from '../../../../app/hooks';
import {
  setCurrentTvShowSeasonStep,
  setTvShowSeason,
} from '../../../../app/slices/addTvShowSeasonSlice';
import { IStep2FormInput } from '../../../../interfaces/forms/TVShowSeasonForm';

export const Step2 = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep2FormInput>({
    // defaultValues: {
    //   tvShowSeason: 0,
    // },
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IStep2FormInput> = (data) => {
    // console.log('DATA MOVIE STEP 2:', data);
    dispatch(setTvShowSeason(data));
    dispatch(setCurrentTvShowSeasonStep(3));
  };

  return (
    <>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="tvShowSeason">TV Show Season *</Label>
            <Controller
              name="tvShowSeason"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="tvShowSeason"
                  placeholder="Enter the tv show season"
                  type="number"
                  invalid={!!errors.tvShowSeason}
                  {...field}
                />
              )}
            />
            <FormFeedback>The tv show season is required</FormFeedback>
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
