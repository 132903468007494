import { Button, FormFileInput } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  setCurrentMovieStep,
  // setChaptersMovieData,
} from '../../../../app/slices/addMediaSlice';
import { RootState } from '../../../../app/store';
import { IMovieStep9FormInput } from '../../../../interfaces/forms/IMovieStep9Form';

import {
  // useCreateMovieMutation,
  usePatchMovieMutation,
} from '../../../../app/services/movieServerApi';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';

export const MovieStep9 = () => {
  const dispatch = useAppDispatch();

  const chaptersMovieData = useAppSelector(
    (state: RootState) => state.addMedia.chaptersMovieData,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMovieStep9FormInput>({
    defaultValues: chaptersMovieData,
  });
  const [patchMovie, { isLoading, isError, isSuccess, error }] =
    usePatchMovieMutation();

  const onSubmit: SubmitHandler<IMovieStep9FormInput> = (formdata) => {
    // dispatch(setChaptersMovieData(formdata));
    // dispatch(setCurrentMovieStep(10));
    // console.log('DATA MOVIE STEP 9:', formdata);
    formdata.step = 'chapters_movie_data';
    patchMovie(formdata).then(function (result: any) {
      if (result && result.data) {
        // dispatch(setNewMovieId(result.data.movieId));
        dispatch(setCurrentMovieStep(10));
      }
    });
  };

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={9}>
            <FormGroup>
              <Label for="chaptersFile">Chapters File *</Label>
              {/* <Controller
                name="chaptersFile"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="chaptersFile"
                    placeholder="Select the movie's chapter's file"
                    type="file"
                    invalid={!!errors.chaptersFile}
                    {...field}
                  />
                )}
              /> */}
              <FormFileInput
                control={control}
                name={'chaptersFile'}
                id="chaptersFile"
                placeholder={"Select the movie's chapter's file"}
                required={true}
                error={errors.chaptersFile}
                accept=".txt"
              />
              <FormFeedback>
                {"The movie's chapters file is required"}
              </FormFeedback>
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <Label for="hasNamedChapters">Has Named Chapters?</Label>
              <Controller
                name={'hasNamedChapters'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="checkbox"
                    style={{ marginTop: '10px' }}
                    // {...field}
                    // defaultChecked={field.value}
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};
