// import { useState } from 'react';
import { LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
// import { useGetMediaDataQuery } from '../../../../app/services/tvShowServerApi';
import errorIcon from '../../../../assets/error.svg';
// import noDataIcon from '../../../../assets/nodata.svg';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { TVShowForm } from './TVShowForm';
import { useEffect } from 'react';
import { useGetTvMediaDataQuery } from '../../../../app/services/tvShowServerApi';

export const Step3 = () => {
  const {
    // data = {},
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTvMediaDataQuery({
    selectedItunesResult: useAppSelector(
      (state: RootState) => state.addTvShow.selectedItunesResult,
    ),
    selectedImdbResult: useAppSelector(
      (state: RootState) => state.addTvShow.selectedImdbResult,
    ),
    selectedItunesCountry: useAppSelector(
      (state: RootState) => state.addTvShow.selectedItunesCountry,
    ),
  });

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  return (
    <>
      {isLoading || isFetching ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : isSuccess ? (
        <TVShowForm />
      ) : null}
    </>
  );
};
