import './MovieList.scss';
/* eslint-disable import/no-unresolved */
import { SwiperSlide, Swiper } from 'swiper/react';
import { MovieCard, LoadingSpinner } from '../';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';
import { useGetMoviesQuery, useGetSimilarMoviesQuery } from '../../app/services/movieServerApi';
import errorIcon from '../../assets/error.svg';
import noDataIcon from '../../assets/nodata.svg';

export const MovieList = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);
  const {
    data: moviesData = {},
    isLoading: isLoadingMovies,
    // isSuccess,
    // isFetching,
    isError: isErrorMovies,
    error: errorMovies,
  } = useGetMoviesQuery({ page: 1, mode: 'home' },
    {
      skip: props?.mode === "similar",
    });

  const {
    data: similarMoviesData = {},
    isLoading: isLoadingSimilarMovies,
    // isSuccessSimilar,
    // isFetchingSimilar,
    isError: isErrorSimilarMovies,
    error: errorSimilarMovies,
  } = useGetSimilarMoviesQuery({ page: 1, mode: 'similar', id: props.ulid },
    {
      skip: props?.mode !== "similar",
    },);

  const data = props?.mode === "similar" ? similarMoviesData : moviesData;
  const isLoading = props?.mode === "similar" ? isLoadingSimilarMovies : isLoadingMovies;
  const isError = props?.mode === "similar" ? isErrorSimilarMovies : isErrorMovies;
  const error = props?.mode === "similar" ? errorSimilarMovies : errorMovies;

  return (
    <div className="movie-list">
      {isLoading ? (
        <div className="list-no-item">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : data.results.length === 0 ? (
        <div className="list-no-item">
          <img src={noDataIcon} alt="no-item-icon" />
          <p>{'No Items'}</p>
        </div>
      ) : (
        <Swiper
          grabCursor={true}
          spaceBetween={10}
          slidesPerView={'auto'}
          mousewheel={false}
          keyboard={true}
        >
          {data.results.map((item: any, i: number) => (
            <SwiperSlide key={i}>
              <MovieCard item={item} category={props.category} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
