import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IStep3FormInput } from '../../../../interfaces/forms/TVShowForm';
import {
  setCurrentStep,
  setGeneralTvShowData,
} from '../../../../app/slices/addTvShowSlice';

// export const MovieForm = ({ data }: any) => {
export const TVShowForm = () => {
  const dispatch = useAppDispatch();

  const generalTvShowData = useAppSelector(
    (state: RootState) => state.addTvShow.generalTvShowData,
  );

  const ratings = useAppSelector(
    (state: RootState) => state.addTvShow.ratings,
  );

  const {
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm<IStep3FormInput>({
    // defaultValues: data.movieData,
    defaultValues: generalTvShowData,

    //   defaultValues: useMemo(() => {
    //     return data?.movieData;
    // }, [data])
  });

  const onSubmit: SubmitHandler<IStep3FormInput> = (formdata) => {
    // login(data).then(function (result: any) {
    //   if (result && result.data) {
    dispatch(setGeneralTvShowData(formdata));
    dispatch(setCurrentStep(4));

    //   }
    // })
    // console.log('DATA TV SHOW STEP 3:', formdata);
  };

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="title">TV Show Title *</Label>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="title"
                    placeholder="Enter the tv show's title"
                    type="text"
                    invalid={!!errors.title}
                    {...field}
                  />
                )}
              />
              <FormFeedback>{"The tv show's title is required"}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="originalTitle">Original Title</Label>
              <Controller
                name="originalTitle"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="originalTitle"
                    placeholder="Enter the tv show's original title"
                    type="text"
                    invalid={!!errors.originalTitle}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="shortDescription">Short Description *</Label>
              <Controller
                name="shortDescription"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="shortDescription"
                    placeholder="Enter the tv show's short description"
                    type="textarea"
                    invalid={!!errors.shortDescription}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show's short description is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="description">Description *</Label>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="description"
                    placeholder="Enter the tv show's description"
                    type="textarea"
                    invalid={!!errors.description}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show's description is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="startYear">Start Year *</Label>
              <Controller
                name="startYear"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="startYear"
                    placeholder="Enter the tv show's start year"
                    type="text"
                    invalid={!!errors.startYear}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show's start year is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="endYear">End Year</Label>
              <Controller
                name="endYear"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="endYear"
                    placeholder="Enter the tv show's end year"
                    type="text"
                    invalid={!!errors.endYear}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="rating">Rating *</Label>
              <Controller
                name="rating"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={ratings}
                    placeholder="Select the tv show's rating"
                    error={errors.rating}
                  />
                )}
              />
              {errors.rating && (
                <div className="invalid-message">
                  {"The tv show's rating is required"}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="imdbId">IMDb ID *</Label>
              <Controller
                name="imdbId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="imdbId"
                    placeholder="Enter the tv show's IMDb ID"
                    type="text"
                    invalid={!!errors.imdbId}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The tv show's IMDb ID is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="imdbRating">IMDb Rating</Label>
              <Controller
                name="imdbRating"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="imdbRating"
                    placeholder="Enter the tv show's IMDb rating"
                    type="text"
                    invalid={!!errors.imdbRating}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};
