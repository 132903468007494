import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useAppDispatch } from '../../../../app/hooks';
import {
  setCurrentTvShowSeasonStep,
  setSelectedDataSource,
} from '../../../../app/slices/addTvShowSeasonSlice';

export const Step3 = () => {
  const dispatch = useAppDispatch();
  const setSelectedOption = (selectedOption: string) => {
    dispatch(setSelectedDataSource(selectedOption));
    dispatch(
      setCurrentTvShowSeasonStep(selectedOption === 'itunesAndImdb' ? 4 : 5),
    );
  };

  return (
    <div className="buttons-container">
      <Button onClick={() => setSelectedOption('itunesAndImdb')}>
        <i className="bi bi-film"></i> iTunes and IMDb
      </Button>
      <Button onClick={() => setSelectedOption('justImdb')}>
        <i className="bi bi-tv"></i> Just IMDb
      </Button>
    </div>
  );
};
