import { useEffect, useState } from 'react';
import './MediaGrid.scss';
import { Button, Input } from '../../components';

export const MediaSearch = (props: any) => {
  const [searchKeyword, setSearchKeyword] = useState(
    props.currentSearchParams.keyword,
  );

  const serializeFormQuery = (keyword: string) => {
    const currentSearchParams = props.currentSearchParams;
    const new_obj = { ...currentSearchParams, keyword: keyword, page: 1 };
    return new_obj;
  };

  const searchItems = () => {
    const params = serializeFormQuery(searchKeyword);
    props.setCurrentSearchParamsFunction(params);
    const new_obj = { ...params };
    delete new_obj.page;
    delete new_obj.mode;
    props.setParamsFunction(new_obj);
  };
  useEffect(() => {
    const enterEvent = (e: any) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        searchItems();
      }
    };
    document.addEventListener('keyup', enterEvent);
    return () => {
      document.removeEventListener('keyup', enterEvent);
    };
    // eslint-disable-next-line
  }, [searchKeyword]);

  return (
    <>
      <div className="media-search">
        <Input
          type="text"
          placeholder="Enter keyword"
          value={searchKeyword}
          onChange={(e: any) => setSearchKeyword(e.target.value)}
        />
        <Button className="small" onClick={() => searchItems()}>
          Search
        </Button>
      </div>
    </>
  );
};
