import '../AddMedia.scss';
import { Step1, Step2, Step3, Step4, Step5, Step6, Step7 } from './Steps';
import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';

export const AddTVShowSeason = () => {
  const currentStep = useAppSelector(
    (state: RootState) => state.addTvShowSeason.currentTvShowSeasonStep,
  );
  return (
    <div className="add-movie-container">
      {currentStep === 1 && <Step1 />}
      {currentStep === 2 && <Step2 />}
      {currentStep === 3 && <Step3 />}
      {currentStep === 4 && <Step4 />}
      {currentStep === 5 && <Step5 />}
      {currentStep === 6 && <Step6 />}
      {currentStep === 7 && <Step7 />}
    </div>
  );
};
