import { useEffect } from 'react';
// import { useNavigate } from 'react-router';

import './MediaGrid.scss';
// import { useDispatch } from 'react-redux';
// import { mediaGridSelector, fetchFilteredMediaGridItems, setTitle } from '../../slices/mediaGrid'
import { Button, Input } from '../../components';

// import tmdbApi, { category, movieType, tvType } from '../../api/tmdbApi';

export const MediaSearch = (props: any) => {
  props;
  // console.log(props);
  // const navigate = useNavigate();
  // const { mediaGridItems, loading, hasErrors, isEmpty, title } = useSelector(mediaGridSelector)
  // const dispatch = useDispatch();

  const searchItems = () => {
    // console.log("LOG", title)
    // if (title.trim().length > 0) {
    //     navigate(`/${props.category}/search/${encodeURIComponent(title)}`);
    //     dispatch(fetchFilteredMediaGridItems(props.category, 1, title))
    // } else {
    //     navigate(`/${props.category}`);
    //     dispatch(fetchFilteredMediaGridItems(props.category, 1, title))
    // }
  };

  useEffect(() => {
    // console.log('EFFECT 1');
    const enterEvent = (e: any) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        searchItems();
      }
    };
    // console.log('EFFECT 2');
    document.addEventListener('keyup', enterEvent);
    return () => {
      // console.log('EFFECT 3');
      document.removeEventListener('keyup', enterEvent);
    };
    // }, [title]);
  }, []);

  return (
    <>
      <div className="media-search">
        <Input
          type="text"
          placeholder="Enter keyword"
          // value={title}
          // onChange={(e: any) => dispatch(setTitle(e.target.value))}
        />
        <Button className="small" onClick={() => searchItems()}>
          Search
        </Button>

        {/*<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown button
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>*/}
      </div>
    </>
  );
};
