import { Link } from 'react-router-dom';
import {
  MediaSlide,
  OutlineButton,
  MovieList,
  TVShowList,
  AutographList,
} from '../../components';
// import { useGetArtistsQuery, useCreateArtistMutation  } from '../../app/services/movieServerApi';
// import { useState } from 'react';

export const Home = () => {
  // const [page, setPage] = useState(1);
  // const {
  //   data: movies = [],
  //   isLoading,
  //   isFetching,
  //   isError,
  //   error,
  // } = useGetArtistsQuery(page);
  // const [createArtist, { isLoading: isLoadingCreate }] = useCreateArtistMutation();
  return (
    <>
      <MediaSlide />
      <div className="container">
        {/* <button onClick={() => createArtist({name: 'pepe', ulid: '123', imdbId: '11', tmdbId: '22'})}>Create Artist</button> */}
        <div className="media-section media-mb-3">
          <div className="media-section__header media-mb-1">
            <h2 className="media-h2">Latest Movies</h2>
            <Link className="media-a" to="/movies">
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={'movies'} type={'movieType.popular'} />
        </div>
        <div className="media-section media-mb-3">
          <div className="media-section__header media-mb-1">
            <h2 className="media-h2">Latest TV Shows</h2>
            <Link className="media-a" to="/tv-shows">
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <TVShowList category={'tv-shows'} type={'tvType.popular'} />
        </div>
        <div className="media-section media-mb-3">
          <div className="media-section__header media-mb-1">
            <h2 className="media-h2">Latest Autographs</h2>
            <Link className="media-a" to="/autographs">
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <AutographList category={'autographs'} type={'tvType.popular'} />
        </div>
      </div>
    </>
  );
};
