import './EpisodeList.scss';
/* eslint-disable import/no-unresolved */
import { SwiperSlide, Swiper } from 'swiper/react';
import { EpisodeCard } from '../../components';

export const EpisodeList = (props: any) => {
  return (
    <div className="episode-list">
      <Swiper grabCursor={true} spaceBetween={10} slidesPerView={'auto'}>
        {props.episodes &&
          props.episodes.map((item: any, i: number) => (
            <SwiperSlide key={i}>
              <EpisodeCard
                tvShowID={props.tvShowID}
                seasonID={props.seasonID}
                item={item}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
