/* eslint-disable import/no-unresolved */
import {
  SelectableMovieCard,
  SelectableTVShowCard,
} from '../../../../components';
import '../../AddMedia.scss';
import noDataIcon from '../../../../assets/nodata.svg';
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';
// import SwiperCore, { Keyboard, Mousewheel } from 'swiper'

export const SearchResults = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);

  return (
    <>
      {props.selectedDataSource === 'itunesAndImdb' && (
        <>
          <div className="media-section__header">
            <h2 className="media-h2">iTunes Results</h2>
          </div>
          <div className="movie-list">
            {props.data?.itunesSearchMovieResults?.length === 0 ? (
              <div className="list-no-item-add">
                <img src={noDataIcon} alt="no-item-icon" />
                <p>{'No Items'}</p>
              </div>
            ) : props.data?.itunesSearchMovieResults?.length ? (
              <Swiper
                grabCursor={true}
                spaceBetween={10}
                slidesPerView={'auto'}
                mousewheel={false}
                keyboard={true}
              >
                {props.data.itunesSearchMovieResults.map(
                  (item: any, i: number) => (
                    <SwiperSlide
                      key={i}
                      onClick={() => props.setSelectedItunesResult(item)}
                    >
                      <SelectableTVShowCard
                        item={item}
                        selected={props.selectedItunesResult === item}
                      />
                    </SwiperSlide>
                  ),
                )}
              </Swiper>
            ) : null}
          </div>
        </>
      )}

      <div className="media-section__header media-mb-1">
        <h2 className="media-h2">IMDb Results</h2>
      </div>
      <div className="movie-list">
        {props.data?.imdbSearchMovieResults?.length === 0 ? (
          <div className="list-no-item-single">
            <img src={noDataIcon} alt="no-item-icon" />
            <p>{'No Items'}</p>
          </div>
        ) : props.data?.imdbSearchMovieResults?.length ? (
          <Swiper
            grabCursor={true}
            spaceBetween={10}
            slidesPerView={'auto'}
            mousewheel={false}
            keyboard={true}
          >
            {props.data.imdbSearchMovieResults.map((item: any, i: number) => (
              <SwiperSlide
                key={i}
                onClick={() => props.setSelectedImdbResult(item)}
              >
                <SelectableMovieCard
                  item={item}
                  selected={props.selectedImdbResult === item}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
      </div>
    </>
  );
};
