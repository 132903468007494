import { useParams } from 'react-router';

import {
  PageHeader,
  MovieCatalog,
  TVShowCatalog,
  AutographCatalog,
} from '../../components';

// import { category as cate } from '../api/tmdbApi';

export const Catalog = () => {
  const { category } = useParams();

  return (
    <>
      <PageHeader
        title={
          category === 'movies'
            ? 'Movies'
            : category === 'tv-shows'
              ? 'TV Shows'
              : 'Autographs'
        }
      />
      <div className="container">
        <div className="media-section mb-3">
          {category === 'movies' ? (
            <MovieCatalog />
          ) : category === 'tv-shows' ? (
            <TVShowCatalog />
          ) : (
            <AutographCatalog />
          )}
        </div>
      </div>
    </>
  );
};
