import './TVShowSeasonList.scss';

/* eslint-disable import/no-unresolved */
import { SwiperSlide, Swiper } from 'swiper/react';

import { TVShowSeasonCard } from '../../components';

export const TVShowSeasonList = (props: any) => {
  return (
    <div className="tv-show-season-list">
      <Swiper grabCursor={true} spaceBetween={10} slidesPerView={'auto'}>
        {props.seasons &&
          props.seasons.map((item: any, i: number) => (
            <SwiperSlide key={i}>
              <TVShowSeasonCard tvShowID={props.tvShowID} item={item} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
