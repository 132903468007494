import ReactSelect, { StylesConfig } from 'react-select';
import IFormSelect from '../../interfaces/components/IFormSelect';

export const FormSelect = ({
  field,
  options,
  placeholder,
  error,
}: IFormSelect) => {
  // const colourStyles: StylesConfig<ColourOption> = {
  const colourStyles: StylesConfig<any> = {
    control: (styles) => ({
      ...styles,
      borderColor: error ? 'red' : undefined,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? '#79be2d'
            : isFocused
              ? '#97e344'
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? 'white'
            : isFocused
              ? 'white'
              : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  return (
    <ReactSelect
      className="basic-single"
      classNamePrefix="select"
      placeholder={placeholder}
      isDisabled={false}
      isLoading={false}
      isClearable={true}
      isSearchable={true}
      options={options}
      styles={colourStyles}
      {...field}
    />
  );
};
