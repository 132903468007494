/* eslint-disable import/no-unresolved */
import { SelectableTVShowCard } from '../../../../components';
import '../../AddMedia.scss';
import noDataIcon from '../../../../assets/nodata.svg';
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';
// import SwiperCore, { Keyboard, Mousewheel } from 'swiper'

export const TVShowSeasonSearchResults = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);

  return (
    <>
      <div className="media-section__header media-mb-1">
        <h2 className="media-h2">TV Show Season Results</h2>
      </div>
      <div className="movie-list">
        {props.data?.length === 0 ? (
          <div className="list-no-item-single">
            <img src={noDataIcon} alt="no-item-icon" />
            <p>{'No Items'}</p>
          </div>
        ) : props.data?.length ? (
          <Swiper
            grabCursor={true}
            spaceBetween={10}
            slidesPerView={'auto'}
            mousewheel={false}
            keyboard={true}
          >
            {props.data.map((item: any, i: number) => (
              <SwiperSlide
                key={i}
                onClick={() => props.setSelectedResult(item)}
              >
                <SelectableTVShowCard
                  item={item}
                  selected={props.selectedResult === item}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
      </div>
    </>
  );
};
