import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Button as ButtonRs,
  FormFeedback,
  Input,
} from 'reactstrap';
import { Button } from '../../../../components';
import { IMovieStep6FormInput } from '../../../../interfaces/forms/IMovieStep6Form';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import {
  setCastCrewMovieData,
  setCurrentMovieStep,
  setNewMovieId,
} from '../../../../app/slices/addMediaSlice';
import { useEffect } from 'react';
import { useCreateMovieMutation } from '../../../../app/services/movieServerApi';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';

export const MovieStep6 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  const dispatch = useAppDispatch();
  const castCrewMovieData = useAppSelector(
    (state: RootState) => state.addMedia.castCrewMovieData,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMovieStep6FormInput>({
    defaultValues: castCrewMovieData,
  });

  const {
    fields: castFields,
    append: castAppend,
    remove: castRemove,
  } = useFieldArray({ control, name: 'cast' });

  const {
    fields: directorFields,
    append: directorAppend,
    remove: directorRemove,
  } = useFieldArray({ control, name: 'directors' });

  const {
    fields: producerFields,
    append: producerAppend,
    remove: producerRemove,
  } = useFieldArray({ control, name: 'producers' });

  const {
    fields: screenwriterFields,
    append: screenwriterAppend,
    remove: screenwriterRemove,
  } = useFieldArray({ control, name: 'screenwriters' });

  const [createMovie, { isLoading, isError, isSuccess, error }] =
    useCreateMovieMutation();

  const { generalMovieData, secondaryMovieData } = useAppSelector(
    (state: RootState) => state.addMedia,
  );

  const onSubmit: SubmitHandler<IMovieStep6FormInput> = (data) => {
    // console.log('DATA MOVIE STEP 6:', data);
    dispatch(setCastCrewMovieData(data));
    const fullMovieData = {
      ...generalMovieData,
      ...secondaryMovieData,
      ...data,
      mode: 'new',
    };
    // dispatch(setCurrentMovieStep(7));

    createMovie(fullMovieData).then(function (result: any) {
      if (result && result.data) {
        dispatch(setNewMovieId(result.data.movieId));
        dispatch(setCurrentMovieStep(7));
      }
    });
  };

  // console.log('DA ERROR:', error);

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  // console.log('IS LOADING:', isLoading);

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1500,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }
  return (
    <>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="media-h2">Cast</h2>
          {castFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={5}>
                <FormGroup>
                  <Label for="artistName">Artist Name *</Label>
                  <Controller
                    name={`cast.${index}.artistName`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="artistName"
                        placeholder="Enter the artist's name"
                        type="text"
                        invalid={!!errors.cast?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's name is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="role">Role *</Label>
                  <Controller
                    name={`cast.${index}.role`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        id="role"
                        placeholder="Enter the artist's role"
                        type="text"
                        invalid={!!errors.cast?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's role is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="order">Order *</Label>
                  <Controller
                    name={`cast.${index}.order`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="order"
                        placeholder="Enter the artist's order"
                        type="number"
                        invalid={!!errors.cast?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's order is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    castRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          {/* {showGenreMinimumError && (
                <div className="invalid-message">
                  {'At least one genre must be added'}
                </div>
           )} */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                castAppend({});
              }}
            >
              Add Cast Member
            </Button>
          </div>

          <h2 className="media-h2">Directors</h2>
          {directorFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={5}>
                <FormGroup>
                  <Label for="artistName">Artist Name *</Label>
                  <Controller
                    name={`directors.${index}.artistName`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="artistName"
                        placeholder="Enter the artist's name"
                        type="text"
                        invalid={!!errors.directors?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's name is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="role">Role</Label>
                  <Controller
                    name={`directors.${index}.role`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        id="role"
                        placeholder="Enter the artist's role"
                        type="text"
                        invalid={!!errors.directors?.[index]}
                        {...field}
                      />
                    )}
                  />
                  {/* <FormFeedback>
                    {"The artist's role is required"}
                  </FormFeedback> */}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="order">Order *</Label>
                  <Controller
                    name={`directors.${index}.order`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="order"
                        placeholder="Enter the artist's order"
                        type="number"
                        invalid={!!errors.directors?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's order is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    directorRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          {/* {showGenreMinimumError && (
                <div className="invalid-message">
                  {'At least one genre must be added'}
                </div>
           )} */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                directorAppend({});
              }}
            >
              Add Director
            </Button>
          </div>

          <h2 className="media-h2">Producers</h2>
          {producerFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={5}>
                <FormGroup>
                  <Label for="artistName">Artist Name *</Label>
                  <Controller
                    name={`producers.${index}.artistName`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="artistName"
                        placeholder="Enter the artist's name"
                        type="text"
                        invalid={!!errors.producers?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's name is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="role">Role</Label>
                  <Controller
                    name={`producers.${index}.role`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        id="role"
                        placeholder="Enter the artist's role"
                        type="text"
                        invalid={!!errors.producers?.[index]}
                        {...field}
                      />
                    )}
                  />
                  {/* <FormFeedback>
                    {"The artist's role is required"}
                  </FormFeedback> */}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="order">Order *</Label>
                  <Controller
                    name={`producers.${index}.order`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="order"
                        placeholder="Enter the artist's order"
                        type="number"
                        invalid={!!errors.producers?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's order is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    producerRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          {/* {showGenreMinimumError && (
                <div className="invalid-message">
                  {'At least one genre must be added'}
                </div>
           )} */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                producerAppend({});
              }}
            >
              Add Producer
            </Button>
          </div>

          <h2 className="media-h2">Screenwriters</h2>
          {screenwriterFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={5}>
                <FormGroup>
                  <Label for="artistName">Artist Name *</Label>
                  <Controller
                    name={`screenwriters.${index}.artistName`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        id="artistName"
                        placeholder="Enter the artist's name"
                        type="text"
                        invalid={!!errors.screenwriters?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's name is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="role">Role</Label>
                  <Controller
                    name={`screenwriters.${index}.role`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        id="role"
                        placeholder="Enter the artist's role"
                        type="text"
                        invalid={!!errors.screenwriters?.[index]}
                        {...field}
                      />
                    )}
                  />
                  {/* <FormFeedback>
                    {"The artist's role is required"}
                  </FormFeedback> */}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="order">Order *</Label>
                  <Controller
                    name={`screenwriters.${index}.order`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        id="order"
                        placeholder="Enter the artist's order"
                        type="number"
                        invalid={!!errors.screenwriters?.[index]}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {"The artist's order is required"}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    screenwriterRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          {/* {showGenreMinimumError && (
                <div className="invalid-message">
                  {'At least one genre must be added'}
                </div>
           )} */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                screenwriterAppend({});
              }}
            >
              Add Screenwriter
            </Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Create Movie</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
