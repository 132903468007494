import { createSlice } from '@reduxjs/toolkit';
// import { extendedApiSlice } from '../services/movieServerApi'
export interface AddTvShowSeasonEpisodeState {
  currentTvShowSeasonEpisodeStep: number | null;
  selectedTvShowSeasonResult: any;
  tvShowSeason: number | null;
  selectedAddType: string | null;
  selectedDataSource: string | null;
  selectedItunesResult: any;
  selectedImdbResult: any;
  selectedItunesCountry: string | null;
  generalTvShowSeasonEpisodeData: any;
  secondaryTvShowData: any;
  castCrewMovieData: any;
  imagesTvShowSeasonEpisodeData: any;
  languagesTvShowSeasonEpisodeData: any;
  chaptersMovieData: any;
  finalTvShowData: any;
  generalTvShowSeasonData: any;
  castCrewTvShowSeasonEpisodeData: any;
  finalTvShowSeasonEpisodeData: any;
  tvShowSeasonEpisodesData: any;
  studios: any[];
  ratings: any[];
  qualities: any[];
  formats: any[];
  sources: any[];
  genres: any[];
  networks: any[];
  countries: any[];
  tags: any[];
  collections: any[];
  languages: any[];
  accessLevels: any[];
  newTvShowSeasonEpisodeId: string;
  newTvShowSeasonEpisodeUlid: string;
  selectedTvShowSeasonEpisode: string;
}

const initialState: AddTvShowSeasonEpisodeState = {
  currentTvShowSeasonEpisodeStep: 1,
  tvShowSeason: null,
  selectedTvShowSeasonResult: null,
  selectedAddType: null,
  selectedDataSource: null,
  selectedItunesResult: null,
  selectedImdbResult: null,
  selectedItunesCountry: null,
  generalTvShowSeasonEpisodeData: null,
  secondaryTvShowData: null,
  castCrewMovieData: null,
  imagesTvShowSeasonEpisodeData: null,
  languagesTvShowSeasonEpisodeData: null,
  chaptersMovieData: { hasNamedChapters: false },
  finalTvShowData: {},
  tvShowSeasonEpisodesData: null,
  generalTvShowSeasonData: null,
  castCrewTvShowSeasonEpisodeData: null,
  finalTvShowSeasonEpisodeData: null,
  studios: [],
  ratings: [],
  qualities: [],
  formats: [],
  networks: [],
  sources: [],
  genres: [],
  countries: [],
  tags: [],
  collections: [],
  languages: [],
  accessLevels: [],
  newTvShowSeasonEpisodeId: '',
  newTvShowSeasonEpisodeUlid: '',
  selectedTvShowSeasonEpisode: '',
};

export const addTvShowSeasonEpisodeSlice = createSlice({
  name: 'addTvShowSeasonEpisode',
  initialState,
  reducers: {
    setSelectedTvShowSeasonResult: (state, { payload }) => {
      state.selectedTvShowSeasonResult = payload;
    },
    setTvShowSeason: (state, { payload }) => {
      state.tvShowSeason = payload.tvShowSeason;
    },
    setCurrentTvShowSeasonEpisodeStep: (state, { payload }) => {
      state.currentTvShowSeasonEpisodeStep = payload;
    },
    setTvShowSeasonEpisodesData: (state, { payload }) => {
      state.tvShowSeasonEpisodesData = payload;
    },
    setSelectedAddType: (state, { payload }) => {
      state.selectedAddType = payload;
    },
    setSelectedDataSource: (state, { payload }) => {
      state.selectedDataSource = payload;
    },
    setGeneralTvShowSeasonEpisodeData: (state, { payload }) => {
      state.generalTvShowSeasonEpisodeData = payload;
    },
    setCastCrewTvShowSeasonEpisodeData: (state, { payload }) => {
      state.castCrewTvShowSeasonEpisodeData = payload;
    },
    setImagesTvShowSeasonEpisodeData: (state, { payload }) => {
      state.imagesTvShowSeasonEpisodeData = payload;
    },
    setLanguagesTvShowSeasonEpisodeData: (state, { payload }) => {
      state.languagesTvShowSeasonEpisodeData = payload;
    },
    setFinalTvShowData: (state, { payload }) => {
      state.finalTvShowData = payload;
    },
    setNewTvShowSeasonEpisodeId: (state, { payload }) => {
      state.newTvShowSeasonEpisodeId = payload;
    },
    setNewTvShowSeasonEpisodeUlid: (state, { payload }) => {
      state.newTvShowSeasonEpisodeUlid = payload;
    },
    setSelectedTvShowSeasonEpisode: (state, { payload }) => {
      state.selectedTvShowSeasonEpisode = payload;
    },

    setSelectedTvShowSeasonEpisodeData: (state, { payload }) => {
      state.generalTvShowSeasonEpisodeData =
        payload.generalTvShowSeasonEpisodeData;
      state.castCrewTvShowSeasonEpisodeData =
        payload.castCrewTvShowSeasonEpisodeData;
      state.languagesTvShowSeasonEpisodeData =
        payload.languagesTvShowSeasonEpisodeData;
      state.studios = payload.studios;
      state.ratings = payload.ratings;
      state.qualities = payload.qualities;
      state.formats = payload.formats;
      state.sources = payload.sources;

      state.genres = payload.genres;
      state.countries = payload.countries;
      state.tags = payload.tags;
      state.collections = payload.collections;

      state.languages = payload.languages;
      state.accessLevels = payload.accessLevels;
    },

    setFinalTvShowSeasonEpisodeData: (state, { payload }) => {
      state.finalTvShowSeasonEpisodeData = payload;
    },
    resetEpisodeState: () => initialState,
  },
});

export const {
  setSelectedTvShowSeasonResult,
  setCurrentTvShowSeasonEpisodeStep,
  setSelectedAddType,
  setSelectedDataSource,
  setCastCrewTvShowSeasonEpisodeData,
  setImagesTvShowSeasonEpisodeData,
  setLanguagesTvShowSeasonEpisodeData,
  setFinalTvShowData,
  setNewTvShowSeasonEpisodeId,
  setNewTvShowSeasonEpisodeUlid,
  setTvShowSeason,
  setTvShowSeasonEpisodesData,
  setSelectedTvShowSeasonEpisode,
  setSelectedTvShowSeasonEpisodeData,
  setGeneralTvShowSeasonEpisodeData,
  setFinalTvShowSeasonEpisodeData,
  resetEpisodeState,
} = addTvShowSeasonEpisodeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default addTvShowSeasonEpisodeSlice.reducer;
