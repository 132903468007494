import { createSlice } from '@reduxjs/toolkit';

export interface AuthenticationState {
  userAccessToken: string | null;
  userRefreshToken: string | null;
}

const userAccessToken = localStorage.getItem('userAccessToken')
  ? localStorage.getItem('userAccessToken')
  : null;

const userRefreshToken = localStorage.getItem('userRefreshToken')
  ? localStorage.getItem('userRefreshToken')
  : null;

const initialState: AuthenticationState = {
  userAccessToken: userAccessToken,
  userRefreshToken: userRefreshToken,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginUser: (state, { payload }) => {
      localStorage.setItem('userAccessToken', payload.access);
      localStorage.setItem('userRefreshToken', payload.refresh);
      state.userAccessToken = payload.access;
      state.userRefreshToken = payload.refresh;
    },
    setAccessToken: (state, { payload }) => {
      localStorage.setItem('userAccessToken', payload.access);
      state.userAccessToken = payload.access;
    },
    logout: (state) => {
      localStorage.removeItem('userAccessToken'); // deletes token from storage
      localStorage.removeItem('userRefreshToken'); // deletes token from storage
      state.userAccessToken = null;
      state.userRefreshToken = null;
    },
  },
});

export const { loginUser, setAccessToken, logout } =
  authenticationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default authenticationSlice.reducer;
