import { createSlice } from '@reduxjs/toolkit';
// import { extendedApiSlice } from '../services/movieServerApi'
export interface AddTvShowSeasonState {
  currentTvShowSeasonStep: number | null;
  selectedTvShowResult: any;
  tvShowSeason: number | null;
  selectedAddType: string | null;
  selectedDataSource: string | null;
  selectedItunesResult: any;
  selectedImdbResult: any;
  selectedItunesCountry: string | null;
  generalTvShowData: any;
  secondaryTvShowData: any;
  castCrewMovieData: any;
  imagesMovieData: any;
  languagesMovieData: any;
  chaptersMovieData: any;
  finalTvShowData: any;
  generalTvShowSeasonData: any;
  castCrewTvShowSeasonData: any;
  studios: any[];
  ratings: any[];
  qualities: any[];
  formats: any[];
  sources: any[];
  genres: any[];
  networks: any[];
  countries: any[];
  tags: any[];
  collections: any[];
  languages: any[];
  accessLevels: any[];
  newTvShowSeasonId: string;
  newTvShowSeasonUlid: string;
}

const initialState: AddTvShowSeasonState = {
  currentTvShowSeasonStep: 1,
  tvShowSeason: null,
  selectedTvShowResult: null,
  selectedAddType: null,
  selectedDataSource: null,
  selectedItunesResult: null,
  selectedImdbResult: null,
  selectedItunesCountry: null,
  generalTvShowData: null,
  secondaryTvShowData: null,
  castCrewMovieData: null,
  imagesMovieData: null,
  languagesMovieData: null,
  chaptersMovieData: { hasNamedChapters: false },
  finalTvShowData: {},
  generalTvShowSeasonData: null,
  castCrewTvShowSeasonData: null,
  studios: [],
  ratings: [],
  qualities: [],
  formats: [],
  networks: [],
  sources: [],
  genres: [],
  countries: [],
  tags: [],
  collections: [],
  languages: [],
  accessLevels: [],
  newTvShowSeasonId: '',
  newTvShowSeasonUlid: '',
};

export const addTvShowSeasonSlice = createSlice({
  name: 'addTvShowSeason',
  initialState,
  reducers: {
    setSelectedTvShowResult: (state, { payload }) => {
      state.selectedTvShowResult = payload;
    },
    setTvShowSeason: (state, { payload }) => {
      state.tvShowSeason = payload.tvShowSeason;
    },
    setSelectedItunesAndImdbResults: (state, { payload }) => {
      state.selectedItunesResult = payload.selectedItunesResult;
      state.selectedImdbResult = payload.selectedImdbResult;
      state.selectedItunesCountry = payload.selectedItunesCountry;
    },
    setCurrentTvShowSeasonStep: (state, { payload }) => {
      state.currentTvShowSeasonStep = payload;
    },
    setSelectedAddType: (state, { payload }) => {
      state.selectedAddType = payload;
    },
    setSelectedDataSource: (state, { payload }) => {
      state.selectedDataSource = payload;
    },
    setGeneralTvShowData: (state, { payload }) => {
      state.generalTvShowData = payload;
    },
    setSecondaryTvShowData: (state, { payload }) => {
      state.secondaryTvShowData = payload;
    },
    setCastCrewMovieData: (state, { payload }) => {
      state.castCrewMovieData = payload;
    },
    setImagesMovieData: (state, { payload }) => {
      state.imagesMovieData = payload;
    },
    setLanguagesMovieData: (state, { payload }) => {
      state.languagesMovieData = payload;
    },
    setChaptersMovieData: (state, { payload }) => {
      state.chaptersMovieData = payload;
    },
    setFinalTvShowData: (state, { payload }) => {
      state.finalTvShowData = payload;
    },
    setNewTvShowSeasonId: (state, { payload }) => {
      state.newTvShowSeasonId = payload;
    },
    setNewTvShowSeasonUlid: (state, { payload }) => {
      state.newTvShowSeasonUlid = payload;
    },

    setTvShowSeasonData: (state, { payload }) => {
      state.generalTvShowSeasonData = payload.generalTvShowSeasonData;
      state.castCrewTvShowSeasonData = payload.castCrewTvShowSeasonData;
      state.networks = payload.networks;
    },
    resetSeasonState: () => initialState,
  },
});

export const {
  setSelectedTvShowResult,
  setSelectedItunesAndImdbResults,
  setCurrentTvShowSeasonStep,
  setSelectedAddType,
  setSelectedDataSource,
  setGeneralTvShowData,
  setSecondaryTvShowData,
  setCastCrewMovieData,
  setImagesMovieData,
  setLanguagesMovieData,
  setChaptersMovieData,
  setFinalTvShowData,
  setNewTvShowSeasonId,
  setNewTvShowSeasonUlid,
  setTvShowSeason,
  setTvShowSeasonData,
  resetSeasonState,
} = addTvShowSeasonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default addTvShowSeasonSlice.reducer;
