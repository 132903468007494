// import { useEffect } from 'react';
import { useParams } from 'react-router';

import './MediaGrid.scss';
// import { useDispatch, useSelector } from 'react-redux'
// import { mediaGridSelector, fetchMediaGridItems, fetchFilteredMediaGridItems, setTitle, setPage } from '../../slices/mediaGrid'
import { MovieCard, TVShowCard, AutographCard } from '../../components';
import { MediaSearch } from './MediaSearch';
import { useGetMoviesQuery } from '../../app/services/movieServerApi';
import { useGetTVShowsQuery } from '../../app/services/tvShowServerApi';
import { useGetAutographsQuery } from '../../app/services/autographServerApi';

export const MediaGrid = (props: any) => {
  const {
    keyword,
    // collection,
    // tag,
    // studio
  } = useParams();
  // const { mediaGridItems, loading, hasErrors, isEmpty, page, totalPages, title, currentTitle, movieItems, tvShowItems, autographItems,
  //     moviePage, totalMoviePages, tvShowPage, totalTvShowPages, autographPage, totalAutographPages } = useSelector(mediaGridSelector)
  // const dispatch = useDispatch()
  // const selectedArtistId = props.selectedArtistId
  // useEffect(() => {
  //   dispatch(setPage(1))
  //   window.scrollTo(0, 0)
  //   console.log("selectedArtistId:", props.selectedArtistId)

  //   if (selectedArtistId) {
  //     dispatch(fetchFilteredMediaGridItems(props.category, 1, null, selectedArtistId))

  //   } else if (tag) {
  //     dispatch(fetchFilteredMediaGridItems(props.category, 1, null, null, tag))

  //   } else if (collection) {
  //     dispatch(fetchFilteredMediaGridItems(props.category, 1, null, null, null, collection))

  //   } else if (studio) {
  //     dispatch(fetchFilteredMediaGridItems(props.category, 1, null, null, null, null, studio))

  //   } else if (keyword) {
  //       dispatch(fetchFilteredMediaGridItems(props.category, 1, keyword))
  //   } else {
  //       dispatch(fetchMediaGridItems(props.category, 1))
  //   }

  // }, [dispatch, props.category, keyword, selectedArtistId, collection, tag, studio])

  // useEffect(() => {
  //   dispatch(setTitle(""))
  // }, [dispatch, props.category])

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [title])

  // const renderLoadMoreButton = () => {
  //     if (props.category === "movies") {
  //         if (moviePage <= totalMoviePages) {
  //             return (
  //             <div className="media-grid__loadmore">
  //                 <OutlineButton className="small" onClick={() => dispatch(fetchMediaGridItems(props.category, moviePage, currentTitle, selectedArtistId, tag, collection, studio))}>Load more</OutlineButton>
  //             </div> )
  //         } else {
  //             return null
  //         }
  //     }
  //     else if (props.category === "tv-shows") {
  //         if (tvShowPage <= totalTvShowPages) {
  //             return (
  //             <div className="media-grid__loadmore">
  //                 <OutlineButton className="small" onClick={() => dispatch(fetchMediaGridItems(props.category, tvShowPage, currentTitle, selectedArtistId, tag, collection))}>Load more</OutlineButton>
  //             </div> )
  //         } else {
  //             return null
  //         }
  //     } else if (props.category === "autographs") {
  //         if (autographPage <= totalAutographPages) {
  //             return (
  //             <div className="media-grid__loadmore">
  //                 <OutlineButton className="small" onClick={() => dispatch(fetchMediaGridItems(props.category, autographPage, currentTitle, selectedArtistId, tag, collection))}>Load more</OutlineButton>
  //             </div> )
  //         } else {
  //             return null
  //         }
  //     }

  // }

  const {
    data: movieItems = [],
    // isLoading,
    // isFetching,
    // isError,
    // error,
  } = useGetMoviesQuery({});

  const {
    data: tvShowItems = [],
    // isLoading,
    // isFetching,
    // isError,
    // error,
  } = useGetTVShowsQuery({});

  const {
    data: autographItems = [],
    // isLoading,
    // isFetching,
    // isError,
    // error,
  } = useGetAutographsQuery({});

  return (
    <>
      <div className="section mb-3">
        <MediaSearch category={props.category} keyword={keyword} />
      </div>
      <div className="media-grid">
        {props.category === 'movies'
          ? movieItems.map((item: any, i: number) => (
              <MovieCard category={props.category} item={item} key={i} />
            ))
          : props.category === 'tv-shows'
            ? tvShowItems.map((item: any, i: number) => (
                <TVShowCard category={props.category} item={item} key={i} />
              ))
            : autographItems.map((item: any, i: number) => (
                <AutographCard category={props.category} item={item} key={i} />
              ))}
      </div>
      {/* {
                renderLoadMoreButton()
            } */}
    </>
  );
};
