// import { useEffect } from 'react';
import { useParams } from 'react-router';
import './Detail.scss';
import { MovieDetail, TVShowDetail, AutographDetail } from '../../pages';

export const Detail = () => {
  // const { category, id } = useParams();
  const { category } = useParams();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [category, id]);

  return (
    <>
      {category === 'movies' ? (
        <MovieDetail />
      ) : category === 'tv-shows' ? (
        <TVShowDetail />
      ) : (
        <AutographDetail />
      )}
    </>
  );
};
