import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useEffect } from 'react';
import { setSelectedMediaType } from '../../../../app/slices/addMediaSlice';
import {
  resetAutographState,
  setCurrentAutographStep,
  setSelectedOrderId,
} from '../../../../app/slices/addAutographSlice';

export const Step17 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const newAutographId = useAppSelector(
  //   (state: RootState) => state.addAutograph.newAutographId,
  // );
  const newAutographUlid = useAppSelector(
    (state: RootState) => state.addAutograph.newAutographUlid,
  );
  const selectedOrderId = useAppSelector(
    (state: RootState) => state.addAutograph.selectedOrderId,
  );

  const addAutographSetup = () => {
    dispatch(resetAutographState());
    dispatch(setSelectedOrderId(selectedOrderId));
    dispatch(setSelectedMediaType('autograph'));
    dispatch(setCurrentAutographStep(2));
  };

  const finalSetup = () => {
    navigate(`/autographs/${newAutographUlid}`);
    dispatch(resetAutographState());
  };
  return (
    <>
      {/* <div className="add-media-form-container"> */}
      <div className="media-section__subtitle">
        <h2 className="media-h2">Step 17</h2>
      </div>
      <div className="buttons-container">
        <Button onClick={() => addAutographSetup()}>
          Add Another Autograph
        </Button>
        <Button onClick={() => finalSetup()}>Go To New Autograph Page</Button>
      </div>
      {/* </div> */}
    </>
  );
};
