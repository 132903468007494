import { Route, Routes } from 'react-router-dom';
import {
  Home,
  Catalog,
  Detail,
  TVShowSeasonDetail,
  TVShowEpisodeDetail,
  ArtistCatalog,
  Login,
  AddMedia,
} from '../pages';
import ProtectedRoute from './ProtectedRoute';

export const SiteRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/:category" element={<Catalog />} />
        {/* <Route path="/:category/search/:keyword" element={<Catalog />} />
        <Route path="/:category/search/tag/:tag" element={<Catalog />} />
        <Route
          path="/:category/search/collection/:collection"
          element={<Catalog />}
        />
        <Route path="/:category/search/studio/:studio" element={<Catalog />} /> */}
        <Route path="/:category/:id" element={<Detail />} />
        <Route path="/tv-shows/season/:id" element={<TVShowSeasonDetail />} />
        <Route
          // path="/tv-shows/:id/season/:seasonID/episode/:episodeID"
          path="/tv-shows/season/episode/:id"
          element={<TVShowEpisodeDetail />}
        />
        <Route path="/artists/:id" element={<ArtistCatalog />} />
        <Route path="/add-media" element={<AddMedia />} />
      </Route>
    </Routes>
  );
};
