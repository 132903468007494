import { createSlice } from '@reduxjs/toolkit';
// import { extendedApiSlice } from '../services/movieServerApi'
export interface NavigationState {
  movieCurrentPage: number;
  movieTotalPages: number;
  tvShowCurrentPage: number;
  tvShowTotalPages: number;
  autographCurrentPage: number;
  autographTotalPages: number;
}

// const userAccessToken = localStorage.getItem('userAccessToken')
//   ? localStorage.getItem('userAccessToken')
//   : null;

// const userRefreshToken = localStorage.getItem('userRefreshToken')
//   ? localStorage.getItem('userRefreshToken')
//   : null;

const initialState: NavigationState = {
  movieCurrentPage: 1,
  movieTotalPages: 1,
  tvShowCurrentPage: 1,
  tvShowTotalPages: 1,
  autographCurrentPage: 1,
  autographTotalPages: 1,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setMoviePageStatus: (state, { payload }) => {
      state.movieCurrentPage = payload.currentPage;
      state.movieTotalPages = payload.totalPages;
    },
    setTVShowPageStatus: (state, { payload }) => {
      state.tvShowCurrentPage = payload.currentPage;
      state.tvShowTotalPages = payload.totalPages;
    },
    setAutographPageStatus: (state, { payload }) => {
      state.autographCurrentPage = payload.currentPage;
      state.autographTotalPages = payload.totalPages;
    },
  },

  // extraReducers: (builder) => {
  //   builder.addMatcher(
  //     // mediaManagerServerApi.endpoints.login.matchFulfilled,
  //     extendedApiSlice.endpoints.getMovies.matchFulfilled,
  //     (state, { payload }) => {
  //       console.log("PAYLEY:", payload)
  //       state.movieCurrentPage = payload.currentPage
  //       state.movieTotalPages = payload.totalPages
  //     }
  //   )
  // },
});

export const {
  setMoviePageStatus,
  setTVShowPageStatus,
  setAutographPageStatus,
} = navigationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default navigationSlice.reducer;
