/* eslint-disable import/no-unresolved */
import { SelectableTVShowCard } from '../../../../components';
import '../../AddMedia.scss';
import noDataIcon from '../../../../assets/nodata.svg';
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';

export const SearchResults = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);

  return (
    <>
      <div className="media-section__header">
        <h2 className="media-h2">iTunes Results</h2>
      </div>
      <div className="movie-list">
        {props.data?.itunesSearchTvShowSeasonResults?.length === 0 ? (
          <div className="list-no-item-add">
            <img src={noDataIcon} alt="no-item-icon" />
            <p>{'No Items'}</p>
          </div>
        ) : props.data?.itunesSearchTvShowSeasonResults?.length ? (
          <Swiper
            grabCursor={true}
            spaceBetween={10}
            slidesPerView={'auto'}
            mousewheel={false}
            keyboard={true}
          >
            {props.data.itunesSearchTvShowSeasonResults.map(
              (item: any, i: number) => (
                <SwiperSlide
                  key={i}
                  onClick={() => props.setSelectedItunesResult(item)}
                >
                  <SelectableTVShowCard
                    item={item}
                    selected={props.selectedItunesResult === item}
                  />
                </SwiperSlide>
              ),
            )}
          </Swiper>
        ) : null}
      </div>
    </>
  );
};
