import { Button, FormSelect, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { itunesCountries } from '../../../../shared/constants';
import { useGetItunesTvShowSeasonOptionsQuery } from '../../../../app/services/tvShowServerApi';
import { useState } from 'react';
import errorIcon from '../../../../assets/error.svg';

import { SearchResults } from './SearchResults';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  setCurrentTvShowSeasonStep,
  setSelectedItunesAndImdbResults,
} from '../../../../app/slices/addTvShowSeasonSlice';
import { RootState } from '../../../../app/store';
import { IStep4FormInput } from '../../../../interfaces/forms/TVShowSeasonForm/IStep4Form';

export const Step4 = () => {
  const selectedImdbResult = useAppSelector(
    (state: RootState) => state.addTvShowSeason.selectedTvShowResult,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep4FormInput>({
    defaultValues: {
      tvShowTitle: selectedImdbResult.title,
      itunesCountry: null,
    },
  });

  const [currentSearchParams, setCurrentSearchParams] = useState({});
  const [
    skipGetItunesTvShowSeasonsOptionsQuery,
    setSkipGetItunesTvShowSeasonsOptionsQuery,
  ] = useState(true);

  const [selectedItunesResult, setSelectedItunesResult] = useState<any | null>(
    null,
  );
  const [showResultsContainer, setShowResultsContainer] = useState(false);

  const {
    data = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetItunesTvShowSeasonOptionsQuery(currentSearchParams, {
    skip: skipGetItunesTvShowSeasonsOptionsQuery,
  });

  const dispatch = useAppDispatch();

  const [selectedItunesCountry, setSelectedItunesCountry] = useState<
    string | null
  >(null);

  const onSubmit: SubmitHandler<IStep4FormInput> = (data) => {
    setSelectedItunesCountry(
      data.itunesCountry ? data.itunesCountry.value : null,
    );
    setShowResultsContainer(true);
    setCurrentSearchParams(data);
    setSkipGetItunesTvShowSeasonsOptionsQuery(false);
    // console.log('DATA MOVIE STEP 2:', data);
  };

  const setSearchData = () => {
    dispatch(
      setSelectedItunesAndImdbResults({
        selectedItunesResult: selectedItunesResult.id,
        selectedImdbResult: selectedImdbResult.imdbId,
        selectedItunesCountry: selectedItunesCountry,
      }),
    );
    dispatch(setCurrentTvShowSeasonStep(5));
  };

  const clearSelectedOptions = () => {
    setSelectedItunesResult(null);
  };

  const enableButton = selectedItunesResult;

  return (
    <>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="tvShowTitle">TV Show Title *</Label>
            <Controller
              name="tvShowTitle"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="tvShowTitle"
                  placeholder="Enter the tv show title"
                  type="text"
                  invalid={!!errors.tvShowTitle}
                  {...field}
                />
              )}
            />
            <FormFeedback>The tv show title is required</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="itunesCountry">iTunes Country *</Label>
            <Controller
              name="itunesCountry"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormSelect
                  field={field}
                  options={itunesCountries}
                  placeholder="Select an iTunes Country"
                  error={errors.itunesCountry}
                />
              )}
            />
            {errors.itunesCountry && (
              <div className="invalid-message">
                The iTunes country is required
              </div>
            )}
          </FormGroup>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" onClick={() => clearSelectedOptions()}>
              Search
            </Button>
          </div>
        </Form>
      </div>
      {showResultsContainer && (
        <div className="media-section media-mt-1">
          {isLoading || isFetching ? (
            <div className="list-no-item-add">
              <LoadingSpinner />
            </div>
          ) : isError ? (
            <div className="list-no-item-add">
              <img src={errorIcon} alt="error-icon" />
              <p>{error?.error}</p>
            </div>
          ) : Object.keys(data).length !== 0 ? (
            <SearchResults
              data={data}
              selectedItunesResult={selectedItunesResult}
              setSelectedItunesResult={setSelectedItunesResult}
            />
          ) : null}
        </div>
      )}
      {enableButton && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setSearchData()}>Submit</Button>
        </div>
      )}
    </>
  );
};
