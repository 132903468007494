import { LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import errorIcon from '../../../../assets/error.svg';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { TVShowSeasonForm } from './TVShowSeasonForm';
import { useEffect } from 'react';
import { useGetTvSeasonMediaDataQuery } from '../../../../app/services/tvShowServerApi';

export const Step5 = () => {
  const {
    // data = {},
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTvSeasonMediaDataQuery({
    selectedItunesResult: useAppSelector(
      (state: RootState) => state.addTvShowSeason.selectedItunesResult,
    ),
    selectedImdbResult: useAppSelector(
      (state: RootState) => state.addTvShowSeason.selectedImdbResult,
    ),
    selectedItunesCountry: useAppSelector(
      (state: RootState) => state.addTvShowSeason.selectedItunesCountry,
    ),
    season: useAppSelector(
      (state: RootState) => state.addTvShowSeason.tvShowSeason,
    ),
  });

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  return (
    <>
      {isLoading || isFetching ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : isSuccess ? (
        <TVShowSeasonForm />
      ) : null}
    </>
  );
};
