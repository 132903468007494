// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

import './AutographList.scss';

/* eslint-disable import/no-unresolved */
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { Keyboard, Mousewheel } from 'swiper/modules';
import { AutographCard, LoadingSpinner } from '../';
import { useGetAutographsQuery } from '../../app/services/autographServerApi';
import errorIcon from '../../assets/error.svg';
import noDataIcon from '../../assets/nodata.svg';

export const AutographList = (props: any) => {
  SwiperCore.use([Keyboard, Mousewheel]);
  const {
    data = {},
    isLoading,
    // isSuccess,
    // isFetching,
    isError,
    error,
  } = useGetAutographsQuery({ page: 1 });

  return (
    <div className="autograph-list">
      {isLoading ? (
        <div className="list-no-item">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : data.results.length === 0 ? (
        <div className="list-no-item">
          <img src={noDataIcon} alt="no-item-icon" />
          <p>{'No Items'}</p>
        </div>
      ) : (
        <Swiper
          grabCursor={true}
          spaceBetween={10}
          slidesPerView={'auto'}
          mousewheel={false}
          keyboard={true}
        >
          {data.results.map((item: any, i: number) => (
            <SwiperSlide key={i}>
              <AutographCard item={item} category={props.category} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
