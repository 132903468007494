import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
('../../../../app/slices/addMediaSlice');
import { RootState } from '../../../../app/store';
import { usePatchTvShowSeasonEpisodeMutation } from '../../../../app/services/tvShowServerApi';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IStep7FormInput } from '../../../../interfaces/forms/TVShowSeasonEpisodeForm';
import { setCurrentTvShowSeasonEpisodeStep } from '../../../../app/slices/addTvShowSeasonEpisodeSlice';

export const Step7 = () => {
  // const dispatch = useAppDispatch();

  const finalTvShowSeasonEpisodeData = useAppSelector(
    (state: RootState) =>
      state.addTvShowSeasonEpisode.finalTvShowSeasonEpisodeData,
  );
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep7FormInput>({
    defaultValues: finalTvShowSeasonEpisodeData,
  });

  const [patchTvShowSeasonEpisode, { isLoading, isError, isSuccess, error }] =
    usePatchTvShowSeasonEpisodeMutation();

  const tvShowSeasonEpisodesData = useAppSelector(
    (state: RootState) =>
      state.addTvShowSeasonEpisode.tvShowSeasonEpisodesData,
  );

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      Swal.fire({
        title: 'Episode created succesfully',
        text: 'Redirecting to episode page',
        icon: 'success',
        timer: 1000,
        showConfirmButton: false,
      });
    // Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  const onSubmit: SubmitHandler<IStep7FormInput> = (formdata) => {
    formdata.step = 'final_data';
    formdata.totalEpisodes = tvShowSeasonEpisodesData.length;
    patchTvShowSeasonEpisode(formdata).then(function (result: any) {
      if (result && result.data) {
        // dispatch(setNewMovieId(result.data.movieId));
        // dispatch(setCurrentMovieStep(10));
        // navigate(`/tv-shows/season/episode/${result.data.episodeId}`);
        dispatch(setCurrentTvShowSeasonEpisodeStep(8));
      }
    });
  };

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="filePath">File Path *</Label>
              <Controller
                name="filePath"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="filePath"
                    placeholder="Enter the episode's file path"
                    type="text"
                    invalid={!!errors.filePath}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The episode's file path is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <Label for="editingCompleted">Editing Completed?</Label>
              <Controller
                name={'editingCompleted'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="checkbox"
                    style={{ marginTop: '10px' }}
                    // {...field}
                    // // defaultChecked={field.value}
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="comments">Comments</Label>
              <Controller
                name="comments"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="comments"
                    placeholder="Enter the episode's comments"
                    type="textarea"
                    invalid={!!errors.comments}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Finish</Button>
        </div>
      </Form>
    </div>
  );
};
