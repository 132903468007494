import { Button, FormFileInput } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, FormFeedback, Row, Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';

import { usePatchTvShowSeasonMutation } from '../../../../app/services/tvShowServerApi';
import { IStep6FormInput } from '../../../../interfaces/forms/TVShowSeasonForm';
import { setCurrentTvShowSeasonStep } from '../../../../app/slices/addTvShowSeasonSlice';

// export const MovieForm = ({ data }: any) => {
export const Step6 = () => {
  const imagesMovieData = useAppSelector(
    (state: RootState) => state.addTvShowSeason.imagesMovieData,
  );

  const {
    control,
    handleSubmit,
    // register,
    // reset,
    formState: { errors },
  } = useForm<IStep6FormInput>({
    defaultValues: imagesMovieData,
  });

  const [patchTvShowSeason, { isLoading, isError, isSuccess, error }] =
    usePatchTvShowSeasonMutation();

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IStep6FormInput> = (formdata) => {
    // dispatch(setImagesMovieData(formdata));
    // dispatch(setCurrentMovieStep(8));
    // console.log('DATA MOVIE STEP 7:', formdata);
    // formdata.background = e.target.background.files
    // createMovie(formdata)

    // const formData = new FormData()
    // // formData.append('id', newMovieId)
    // formData.append('poster', formdata.poster ?? "")
    // formData.append('background', formdata.background)
    formdata.step = 'images_data';

    patchTvShowSeason(formdata).then(function (result: any) {
      if (result && result.data) {
        // navigate(`/tv-shows/season/${result.data.tvShowSeasonId}`);
        dispatch(setCurrentTvShowSeasonStep(7));
      }
    });
  };

  const selectedDataSource = useAppSelector(
    (state: RootState) => state.addTvShowSeason.selectedDataSource,
  );

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="cover">
                {selectedDataSource === 'itunesAndImdb' ? 'Cover' : 'Cover *'}
              </Label>
              {/* <Controller
                name="poster"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="poster"
                    placeholder="Select the movie's poster"
                    type="file"
                    invalid={!!errors.poster}
                    {...field}
                  />
                )}
              /> */}
              <FormFileInput
                control={control}
                name="cover"
                id="cover"
                placeholder={"Select the tv show season's cover"}
                required={
                  selectedDataSource === 'itunesAndImdb' ? false : true
                }
                error={!!errors.cover}
                accept=".jpg"
              />
              <FormFeedback>
                {"The tv show season's cover is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="background">Background *</Label>
              {/* <Controller
                name="background"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="background"
                    placeholder="Select the movie's background"
                    type="file"
                    invalid={!!errors.background}
                    // onChange={(e) =>
                    //   field.onChange({ target: { value: e.target.files[0], name: field.name } })
                    // }
                    {...field}
                  />

                // <input
                // id="background"
                // placeholder="Select the movie's background"
                // type="file"
                // invalid={!!errors.background}
                // {...field}
                //     // {...register("background", { required: false })}
                //   />
                )}
              /> */}

              <FormFileInput
                control={control}
                name="background"
                id="background"
                placeholder={"Select the tv show season's background"}
                required={true}
                error={!!errors.background}
                accept=".jpg"
              />
              <FormFeedback>
                {"The tv show season's background is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};
