import './Button.scss';

export const Button = (props: any) => {
  const disabled = props?.disabled === true;
  return (
    <button
      className={`btn-media ${props.className}`}
      type={props.type ?? 'button'}
      onClick={props.onClick ? () => props.onClick() : undefined}
      disabled={disabled}
    >
      {props.children}
    </button>
  );
};

export const OutlineButton = (props: any) => {
  // console.log("BUTTON PROPS:", props)
  return (
    <Button
      className={`btn-media-outline ${props.className}`}
      onClick={props.onClick ? () => props.onClick() : undefined}
    >
      {props.children}
    </Button>
  );
};
