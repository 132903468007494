import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';

const ProtectedRoute = () => {
  const userAccessToken = useAppSelector(
    (state: RootState) => state.authentication.userAccessToken,
  );

  // show unauthorized screen if no user is found in redux store
  if (!userAccessToken) {
    // return (
    //   <div className='unauthorized'>
    //     <h1>Unauthorized :(</h1>
    //     <span>
    //       <NavLink to='/login'>Login</NavLink> to gain access
    //     </span>
    //   </div>
    // )

    return <Navigate to="/login" replace />;
  } else {
    // returns child route elements
    return <Outlet />;
  }
};
export default ProtectedRoute;
